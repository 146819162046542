import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { planResultsTableHeadingStyles as styles } from './planResultsTableHeading.styles'
import { useContext } from 'react'
import { A11YContext } from '../../../contexts/A11YContext'
import { MaterialHomeIcon } from '../../atoms/material-home-icon/MaterialHomeIcon.component'
import { IPlanResultsTableHeading } from './IPlanResultsTableHeading.interface'

/**
 * Renders the table heading on top of the plannen results table
 * @returns A heading that can be used on top of the plannen table
 */
const PlanResultsTableHeading = (props: IPlanResultsTableHeading): JSX.Element | null => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)

  const _styles = styles({ theme: currentTheme })
  const titlePostfix =
    typeof props.municipality === 'string' ? props.municipality : props.municipality?.label

  return (
    <Box sx={_styles.wrapper}>
      <MaterialHomeIcon sx={_styles.icon} />
      <MaterialTypography component={'h2'} sx={_styles.title}>
        {props.municipality ? (
          <>{t('results.HEADER_TITLE_RESULTS', { municipality: titlePostfix })}</>
        ) : (
          <>{t('results.HEADER_TITLE_NO_SEARCH_PERFORMED')}</>
        )}
      </MaterialTypography>
    </Box>
  )
}

export { PlanResultsTableHeading }
