import { SxProps } from '@mui/material'

const fabStyles: SxProps = {
  position: 'fixed',
  bottom: { xs: 25, md: 70 },
  right: 20,
  width: { xs: '40px' },
  height: { xs: '40px' },
  '.MuiSvgIcon-root': {
    width: { xs: 20 },
    height: { xs: 20 },
  },
  display: { xs: 'none', md: 'flex' },
}

export const floatingContactButtonStyles = () => {
  return {
    fab: fabStyles,
  }
}
