import axios, { AxiosResponse } from 'axios'
import { API } from './api'
import { IDossier } from './IDossier.interface'

const dossierAxios = axios.create()

/**
 * Fetches the current dossier status for a given idn number
 * @param idn - The idn number to fetch the dossier status for
 * @returns - The dossier status of a given idn number
 */
export const fetchDossierStatus = (idn: string): Promise<AxiosResponse<IDossier>> => {
  if (!idn) {
    throw new Error('Provide a idn number to proceed')
  }

  const url = API.fetchDossierStatus(idn)
  return dossierAxios.get(url)
}
