import { SxProps } from '@mui/material'
import {
  availibleThemes,
  zaaksysteemBackgroundColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const planCardContainerWrapperStyles = (a11y: Partial<IA11Y>): SxProps => ({
  display: {
    xs: 'block',
    md: 'none',
  },
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? zaaksysteemBackgroundColorHex : 'primary.main',
  paddingTop: 2,
  paddingBottom: 2,
})

const planCardInnerContainerStyles = {
  minHeight: 'calc(100vh - 520px)',
  display: {
    xs: 'block',
    md: 'none',
  },
}

const planTableContainerWrapperStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? zaaksysteemBackgroundColorHex : 'primary.main',
  display: {
    xs: 'none',
    md: 'block',
  },
  py: 5,
  minHeight: {
    md: 'calc(100vh - 565px)',
  },
})

export const homepageStyles = (a11y: IA11Y) => {
  return {
    planCardContainerWrapper: planCardContainerWrapperStyles(a11y),
    planTableContainerWrapper: planTableContainerWrapperStyles(a11y),
    planCardInnerContainer: planCardInnerContainerStyles,
  }
}
