/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { forwardRef, useRef } from 'react'
import { useScroll } from '@use-gesture/react'
import { IFullHeightContainerElement } from './IFullHeightContainerElement.interface'
import { Box } from '@mui/material'

/**
 * Renders a container for react-window that has the height of all elemnts on the page
 * @returns A wrapper <div> that can contain react-window elements
 */
export const FullHeightContainerElement = forwardRef(
  ({ onScroll, children }: IFullHeightContainerElement, ref: any) => {
    const containerRef = useRef<any>(null)

    const useScrollCb = () => {
      if (!(onScroll instanceof Function)) {
        return
      }

      const clientWidth = document.documentElement.clientWidth
      const clientHeight = document.documentElement.clientHeight
      const scrollLeft = document.documentElement.scrollLeft
      const scrollTop = document.documentElement.scrollTop
      const scrollHeight = document.documentElement.scrollHeight
      const scrollWidth = document.documentElement.scrollWidth

      const element = containerRef?.current
      const scrollPos = scrollTop - (element ? element.getBoundingClientRect().top + scrollTop : 0)

      const scrollEvent = {
        currentTarget: {
          clientHeight: clientHeight,
          clientWidth: clientWidth,
          scrollLeft: scrollLeft,
          scrollTop: scrollPos,
          scrollHeight: scrollHeight,
          scrollWidth: scrollWidth,
        },
      }

      onScroll(scrollEvent)
    }

    useScroll(() => useScrollCb(), { target: window })

    ref.current = document.documentElement

    return (
      <Box style={{ position: 'relative' }} ref={containerRef}>
        {children}
      </Box>
    )
  },
)

/* eslint-enable react/display-name */
/* eslint-enable @typescript-eslint/no-explicit-any */
