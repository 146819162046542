import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import dossierStore from '../../../contexts/DossierStoreContext'
import * as dossierService from '../../../services/dossiers'
import { IDossier } from '../../../services/dossiers/IDossier.interface'

/**
 * Fetches the dossier status by a given idn number
 * @param idn - The idn number of the plan
 * @param fetchErrorMessage - The error message to display when there is a fetch error
 */
export const fetchDossierStatus = async (
  idn: string,
  fetchErrorMessage: string,
): Promise<AxiosResponse<IDossier> | null> => {
  try {
    const formattedIdn = idn.split('-')[0]
    if (!formattedIdn) {
      throw new Error()
    }

    dossierStore.setIsDossierLoading(true)
    const fetchResult: AxiosResponse<IDossier> = await dossierService.fetchDossierStatus(
      formattedIdn,
    )
    return fetchResult
  } catch (err) {
    toast(fetchErrorMessage, {
      position: 'bottom-left',
      type: 'error',
    })
  } finally {
    dossierStore.setIsDossierLoading(false)
  }

  return null
}
