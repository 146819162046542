import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single filter icon
 * @param props
 * @returns A single filter icon
 */
const FilterIcon = (props: SvgIconProps): JSX.Element => {
  return <FilterAltIcon {...props} />
}

export { FilterIcon }
