import { Box, Grid } from '@mui/material'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ruimtelijkePlannenConfig } from '../../../config/ruimtelijke-plannen.config'
import { A11YContext } from '../../../contexts/A11YContext'
import { pad } from '../../../helpers/date.helpers'
import { MaterialButton } from '../../atoms/material-button/MaterialButton.component'
import { MaterialCallMadeIcon } from '../../atoms/material-call-made-icon/MaterialCallMadeIcon.component'
import { MaterialCardActionsWrapper } from '../../atoms/material-card-actions-wrapper/MaterialCardActionsWrapper.component'
import { MaterialCardContentWrapper } from '../../atoms/material-card-content-wrapper/MaterialCardContentWrapper.component'
import { MaterialCard } from '../../atoms/material-card/MaterialCard.component'
import { MaterialInfoIcon } from '../../atoms/material-info-icon/MaterialInfoIcon.component'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { IPlanResultCard } from './IPlanResultCard.interface'
import { planResultCardStyles as styles } from './planResultCard.styles'
import { observer } from 'mobx-react'
import { withDossierTooltip } from '../dossier-tooltip/DossierTooltip.component'
import { MaterialChip } from '../../atoms/material-chip/MaterialChip.component'

/**
 * Renders a card that stores information about a plan
 * @returns A single card containing a plan result
 */
const PlanResultCard = (props: IPlanResultCard): JSX.Element => {
  // Hooks
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)
  const rowRef = useRef<any>() // eslint-disable-line @typescript-eslint/no-explicit-any

  // Destructure
  const { data, index, setSize, windowWidth } = props
  const { datum, eigenaar, naam, status, type, idn, imroVersion, deleted } = data[index || 0]
  const _styles = styles({ theme: currentTheme })

  // Effects
  useEffect(() => {
    const marginBottom = 15
    setSize && setSize(index || 0, rowRef.current.getBoundingClientRect().height + marginBottom)
  }, [setSize, index, windowWidth])

  // Constants
  const date = new Date(datum)
  const dateString = `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${date.getFullYear()}`
  const TooltipButton = () => (
    <MaterialButton
      name={t('a11y.labels.SHOW_INFO')}
      size='small'
      variant='outlined'
      startIcon={<MaterialInfoIcon />}
      sx={{ ..._styles.button, ...(deleted ? { display: 'none'} : {})}}
      disabled={deleted}
    >
      {t('results.card.STATUS_BUTTON_TEXT')}
    </MaterialButton>
  )

  const DossierTooltip = withDossierTooltip(TooltipButton, 'top')

  // Render
  return (
    <div ref={rowRef}>
      <MaterialCard variant='outlined' sx={_styles.wrapper}>
        <MaterialCardContentWrapper sx={_styles.content}>
          {datum && dateString && (
            <MaterialTypography sx={_styles.dateText}>
              <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{dateString}</Box>
            </MaterialTypography>
          )}
          {eigenaar && (
            <MaterialTypography sx={_styles.ownerText}>
              <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{eigenaar}</Box>
            </MaterialTypography>
          )}
          {imroVersion && imroVersion === 2008 && (
            <MaterialChip sx={_styles.chip} size='small' label={imroVersion} />
          )}
          {naam && (
            <MaterialTypography sx={_styles.nameText}>
              <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{naam}</Box>
              {deleted ? <Box component={'span'} sx={{ color: 'red', fontWeight: 'semibold'}}>{' '}({t('results.PLAN_REMOVED_LABEL')})</Box> : null }
            </MaterialTypography>
          )}
          <Grid container spacing={2}>
            <Grid item xs={5}>
              {status && (
                <MaterialTypography sx={_styles.statusText}>
                  <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>
                    {t('results.card.STATUS_PREFIX')} <br /> <span>{status}</span>
                  </Box>
                </MaterialTypography>
              )}
            </Grid>
            <Grid item xs={7}>
              {type && (
                <MaterialTypography sx={_styles.typeText}>
                  <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>
                    {t('results.card.TYPE_PREFIX')} <br /> <span>{type}</span>
                  </Box>
                </MaterialTypography>
              )}
            </Grid>
          </Grid>
        </MaterialCardContentWrapper>
        <MaterialCardActionsWrapper sx={_styles.buttonWrapper}>
          <MaterialButton
            name={t('a11y.labels.SHOW_ON_MAP')}
            size='small'
            variant='outlined'
            startIcon={<MaterialCallMadeIcon />}
            sx={{..._styles.button, ...(deleted ? { display: 'none'} : {})}}
            href={`${ruimtelijkePlannenConfig.mapBaseUrl}${idn}`}
            target={'_blank'}
            LinkComponent={'a'}
            disabled={deleted}
          >
            {t('results.card.MAP_BUTTON_TEXT')}
          </MaterialButton>
          <MaterialButton
            name={t('a11y.labels.SHOW_DOCUMENT')}
            size='small'
            variant='outlined'
            startIcon={<MaterialCallMadeIcon />}
            sx={{..._styles.button, ...(deleted ? { display: 'none'} : {})}}
            href={`${ruimtelijkePlannenConfig.documentBaseUrl}/${idn}`}
            target={'_blank'}
            LinkComponent={'a'}
            disabled={deleted}
          >
            {t('results.card.DOCUMENT_BUTTON_TEXT')}
          </MaterialButton>
          <DossierTooltip idn={idn} />
        </MaterialCardActionsWrapper>
      </MaterialCard>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const ObservingPlanResultCard = observer((props: any): JSX.Element | null => {
  return <PlanResultCard {...props} />
})
/* eslint-enable @typescript-eslint/no-explicit-any */

export { PlanResultCard, ObservingPlanResultCard }
