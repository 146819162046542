import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { PlanResultsFilter } from '../../molecules/plan-results-filter/PlanResultsFilter.component'
import { PlanResultsTableHeading } from '../../molecules/plan-results-table-heading/PlanResultsTableHeading.component'
import { IPlanResultsHeader } from './IPlanResultsHeader.interface'
import { planResultsHeaderStyles as styles } from './planResultsHeader.styles'

/**
 * Renders the results header for plans
 * @returns the header thats shown before the results table
 */
const PlanResultsHeader = (props: IPlanResultsHeader): JSX.Element | null => {
  const _styles = styles()

  return (
    <Container>
      <Box className={'header header--plans'} sx={_styles.wrapperMobile}>
        <Box sx={_styles.headingWrapper}>
          <PlanResultsTableHeading municipality={props.selectedMunicipality} />
        </Box>
        <Box>
          <PlanResultsFilter municipality={props.selectedMunicipality} />
        </Box>
      </Box>
      <Box sx={_styles.wrapperDesktop}>
        <PlanResultsTableHeading municipality={props.selectedMunicipality} />
        <PlanResultsFilter municipality={props.selectedMunicipality} />
      </Box>
    </Container>
  )
}

export { PlanResultsHeader }
