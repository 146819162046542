import { SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { availibleThemes, fontSizes } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const wrapperStyles = (a11y: Partial<IA11Y>): SxProps => ({
  marginBottom: 1.5,
  mx: { xs: 1, md: 0 },
  backgroundColor: a11y.theme === availibleThemes.DARK ? 'primary.main' : '',
  color: a11y.theme === availibleThemes.DARK ? 'secondary.contrastText' : 'defaults.black',
  border: '1px soild',
  borderColor: grey[300],
})

const dateTextStyles: SxProps = {
  fontSize: fontSizes.default.xs - 2,
}

const ownerTextStyles: SxProps = {
  marginBottom: 1,
  marginTop: 0.2,
  fontSize: fontSizes.default.xs,
}

const nameTextStyles: SxProps = {
  marginBottom: 2,
  marginTop: 2,
  borderColor: grey[300],
  fontSize: fontSizes.default.md,
}

const statusTextStyles: SxProps = {
  marginBottom: 1,
  fontSize: fontSizes.default.xs,
}

const typeTextStyles: SxProps = {
  fontSize: fontSizes.default.xs,
}

const buttonStyles = (a11y: Partial<IA11Y>): SxProps => ({
  color: a11y.theme === availibleThemes.LIGHT ? 'primary.main' : 'secondary.contrastText',
  borderColor: a11y.theme === availibleThemes.LIGHT ? 'primary.main' : 'secondary.contrastText',
  fontSize: 'small',
  textTransform: 'capitalize',
  '&:hover': {
    borderColor: a11y.theme === availibleThemes.LIGHT ? 'primary.main' : 'secondary.contrastText',
  },
  '&:first-of-type': {
    marginLeft: 1,
  },
  marginBottom: 0.5,
})

const contentStyles: SxProps = {
  paddingBottom: 0,
}

const buttonWrapperStyles: SxProps = {
  paddingBottom: 2,
  flexWrap: 'wrap',
}

const chipStyles = (a11y: Partial<IA11Y>): SxProps => ({
  fontSize: 10,
  textAlign: 'center',
  backgroundColor: a11y.theme === availibleThemes.DARK ? 'secondary.contrastText' : '',
})

export const planResultCardStyles = (a11y: IA11Y) => {
  return {
    wrapper: wrapperStyles(a11y),
    dateText: dateTextStyles,
    ownerText: ownerTextStyles,
    nameText: nameTextStyles,
    statusText: statusTextStyles,
    typeText: typeTextStyles,
    content: contentStyles,
    button: buttonStyles(a11y),
    buttonWrapper: buttonWrapperStyles,
    chip: chipStyles(a11y),
  }
}
