import InfoIcon from '@mui/icons-material/Info'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single info icon
 * @param props
 * @returns A single info icon
 */
const MaterialInfoIcon = (props: SvgIconProps): JSX.Element => {
  return <InfoIcon {...props} />
}

export { MaterialInfoIcon }
