import { CardActions, CardActionsProps } from '@mui/material'

/**
 * Renders a wrapper for a mui card actions
 * @param props
 * @returns A wrapper for the actions in a mui card
 */
const MaterialCardActionsWrapper = (props: CardActionsProps): JSX.Element => {
  const { children, ...rest } = props
  return <CardActions {...rest}>{children}</CardActions>
}

export { MaterialCardActionsWrapper }
