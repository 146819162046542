import { Box } from '@mui/system'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ruimtelijkePlannenConfig } from '../../../config/ruimtelijke-plannen.config'
import { A11YContext } from '../../../contexts/A11YContext'
import { pad } from '../../../helpers/date.helpers'
import { MaterialBookIcon } from '../../atoms/material-book-icon/MaterialBookIcon.component'
import { MaterialChip } from '../../atoms/material-chip/MaterialChip.component'
import { MaterialIconButton } from '../../atoms/material-icon-button/MaterialIconButton.component'
import { MaterialInfoIcon } from '../../atoms/material-info-icon/MaterialInfoIcon.component'
import { MaterialMapIcon } from '../../atoms/material-map-icon/MaterialMapIcon.component'
import { withDossierTooltip } from '../dossier-tooltip/DossierTooltip.component'
import { IPlanResultRow } from './IPlanResultRow.interface'
import { planResultStyles as styles } from './planResult.styles'

/**
 * Renders a row for a plan result
 * @returns A single row containing the plan results
 */
const PlanResultRow = (props: IPlanResultRow): JSX.Element => {
  // Hooks
  const { t } = useTranslation()
  const rowRef = useRef<any>() // eslint-disable-line @typescript-eslint/no-explicit-any
  const { currentTheme } = useContext(A11YContext)

  // Destructure
  const { data, index, setSize, windowWidth } = props
  const { datum, eigenaar, naam, status, type, idn, imroVersion, deleted } = data[index || 0] // eslint-disable-line @typescript-eslint/no-unused-vars

  // Effects
  useEffect(() => {
    setSize && setSize(index || 0, rowRef.current.getBoundingClientRect().height)
  }, [setSize, index, windowWidth])

  // Constants
  const date = new Date(datum)
  const dateString = `${pad(date.getDate())}-${pad(date.getMonth() + 1)}-${date.getFullYear()}`
  const _styles = styles({ theme: currentTheme })

  const TooltipButton = () => (
    <MaterialIconButton 
      href='#' 
      aria-label={t('a11y.labels.SHOW_INFO')} 
      disabled={deleted} 
      sx={deleted ? {display: 'none'} : {}}    
    >
      <MaterialInfoIcon className='icon' />
    </MaterialIconButton>
  )

  const DossierTooltip = withDossierTooltip(TooltipButton, 'right')

  // Render
  return (
    <div className='result-row' ref={rowRef}>
      <Box sx={_styles.columnWrapper}>
        <Box sx={_styles.beforeIconColumn}>
          <DossierTooltip idn={idn} />
        </Box>
        <Box sx={_styles.dateColumn}>
          <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{datum ? dateString : ''}</Box>
        </Box>
        <Box sx={_styles.nameColumn}>
          <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{naam ? naam : ''}</Box>
          {deleted ? <Box component={'span'} sx={{ color: 'red', fontWeight: 'semibold'}}>{' '}({t('results.PLAN_REMOVED_LABEL')})</Box> : null }
        </Box>
        <Box sx={_styles.ownerColumn}>
          <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{eigenaar ? eigenaar : ''}</Box>
        </Box>
        <Box sx={_styles.statusColumn}>
         <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{status ? status : ''}</Box>
        </Box>
        <Box sx={_styles.typeColumn}>
          <Box component={'span'} sx={deleted ? { textDecoration: 'line-through' } : {}}>{type ? type : ''}</Box>
        </Box>
        <Box
          sx={{
            ..._styles.afterIconColumn,
            paddingRight: 0,
            marginRight: 2,
            paddingLeft: 0,
          }}
        >
          {imroVersion && imroVersion === 2008 && (
            <MaterialChip sx={_styles.chip} size='small' label={imroVersion} />
          )}
        </Box>
        <Box
          sx={{ ..._styles.afterIconColumn, paddingRight: 0, marginRight: -0.5, paddingLeft: 0.5 }}
        >
          <MaterialIconButton
            aria-label={t('a11y.labels.SHOW_ON_MAP')}
            href={`${ruimtelijkePlannenConfig.mapBaseUrl}${idn}`}
            target={'_blank'}
            LinkComponent={'a'}
            disabled={deleted}
            sx={deleted ? {opacity: '0.5'} : {}}
          >
            <MaterialMapIcon className='icon' />
          </MaterialIconButton>
        </Box>
        <Box sx={{ ..._styles.afterIconColumn, paddingLeft: 0.5, marginRight: 1 }}>
          <MaterialIconButton
            aria-label={t('a11y.labels.SHOW_DOCUMENT')}
            href={`${ruimtelijkePlannenConfig.documentBaseUrl}/${idn}`}
            target={'_blank'}
            LinkComponent={'a'}
            disabled={deleted}
            sx={deleted ? {opacity: '0.5'} : {}}
          >
            <MaterialBookIcon className='icon' />
          </MaterialIconButton>
        </Box>
      </Box>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const ObservingPlanResultRow = observer((props: any): JSX.Element | null => {
  return <PlanResultRow {...props} />
})
/* eslint-enable @typescript-eslint/no-explicit-any */

export { PlanResultRow, ObservingPlanResultRow }
