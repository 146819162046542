import { apiConfig } from '../../config/api.config'
import { environmentConfig } from '../../config/environment.config'

const IS_DEV = process.env.NODE_ENV === environmentConfig.development

export const API = {
  fetchMunicipalities: IS_DEV
    ? `${apiConfig.prefix}/authorities/municipalities`
    : `${apiConfig.prefix}/authorities/municipalities`,
}
