import { SxProps } from '@mui/material'
import { fontSizes } from '../../../config/material-theme.config'

const columnStyles = {
  display: 'flex',
  'p:first-of-type': {
    marginRight: 0.5,
    fontWeight: 'bold',
  },
}

const textStyles: SxProps = {
  fontSize: fontSizes.default.xs,
}

export const dossierTooltipStyles = {
  column: columnStyles,
  text: textStyles,
}
