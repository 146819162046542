import { PlanResultsHeader } from '../../organisms/plan-results-header/PlanResultsHeader.component'
import { BaseTemplate } from '../../templates/base-template/BaseTemplate.component'
import { observer } from 'mobx-react'
import { Box } from '@mui/system'
import { Container } from '@mui/material'
import * as planService from '../../../services/plans'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect } from 'react'
import { ObservingPlanResultCard } from '../../molecules/plan-result-card/PlanResultCard.component'
import { ObservingPlanResultRow } from '../../molecules/plan-result-row/PlanResultRow.component'
import { ObservingPlanResultHead } from '../../molecules/plan-result-row/PlanResultHead.component'
import { withPlanResults } from '../../organisms/plan-results/PlanResults.component'
import { homepageStyles as styles } from './homepage.styles'
import planStore from '../../../contexts/PlanStoreContext'
import { AxiosResponse } from 'axios'
import { IPlan } from '../../../services/plans/IPlan.interface'
import municipalityStore from '../../../contexts/MunicipalityStoreContext'
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders the homepage
 * @returns a homepage component
 */
const Homepage = observer((): JSX.Element | null => {
  const { t } = useTranslation()
  const PlanResultsWithCards = withPlanResults(ObservingPlanResultCard)
  const PlanResultsWithRows = withPlanResults(ObservingPlanResultRow, ObservingPlanResultHead)

  const { currentTheme } = useContext(A11YContext)
  const _styles = styles({ theme: currentTheme })

  const fetchYesterdaysPlans = async (): Promise<IPlan[] | []> => {
    try {
      const yesterday = new Date(Date.now() - 86400000)
      const fetchResult: AxiosResponse<IPlan[]> = await planService.fetchPlansByDate(yesterday)
      return planStore.normalizePlans(fetchResult)
    } catch (err) {
      toast(t('common.errors.FETCH_ERROR'), {
        position: 'bottom-left',
        type: 'error',
      })
    }

    return []
  }

  useEffect(() => {
    const handle = async () => {
      planStore.setIsLoading(true)
      const results = await fetchYesterdaysPlans()
      planStore.setPlanSearchResults(results)
      planStore.setIsLoading(false)
    }

    handle()
  }, [])

  return (
    <div className='page page--home'>
      <BaseTemplate>
        <PlanResultsHeader selectedMunicipality={municipalityStore.selectedMunicipality} />
        <Box sx={_styles.planCardContainerWrapper}>
          <Container sx={_styles.planCardInnerContainer}>
            <PlanResultsWithCards
              isLoading={planStore.isLoading}
              results={planStore.planSearchResults}
              itemsToConcat={200}
            />
          </Container>
        </Box>
        <Box sx={_styles.planTableContainerWrapper}>
          <Container>
            <PlanResultsWithRows
              isLoading={planStore.isLoading}
              results={planStore.planSearchResults}
              itemsToConcat={200}
              estimatedItemHeight={40}
            />
          </Container>
        </Box>
      </BaseTemplate>
    </div>
  )
})

export { Homepage }
