import { createTheme, ThemeOptions } from '@mui/material'

export const availibleThemes = {
  DARK: 'dark',
  LIGHT: 'light',
}

export const fontSizes = {
  default: {
    xs: 13,
    md: 15,
  },
  bigger: {
    xs: 16,
    md: 18,
  },
}

export const themeCookie = 'theme'
export const mdBreakPointInPx = 895

export const headerFontFamily = 'Lato'
const bodyFontFamily = 'Inter'

const defaultStyles = {
  typography: {
    h1: {
      fontFamily: headerFontFamily,
    },
    h2: {
      fontFamily: headerFontFamily,
    },
    h3: {
      fontFamily: headerFontFamily,
    },
    h4: {
      fontFamily: headerFontFamily,
    },
    h5: {
      fontFamily: headerFontFamily,
    },
    h6: {
      fontFamily: headerFontFamily,
    },
    subtitle1: {
      fontFamily: bodyFontFamily,
      fontSize: '1.875rem',
    },
    subtitle2: {
      fontFamily: bodyFontFamily,
      fontSize: '0.85rem',
    },
    body1: {
      fontFamily: bodyFontFamily,
    },
    body2: {
      fontFamily: bodyFontFamily,
    },
    button: {
      fontFamily: bodyFontFamily,
    },
    caption: {
      fontFamily: bodyFontFamily,
    },
    overline: {
      fontFamily: bodyFontFamily,
    },
  },
}

export const lightTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#006450',
    },
    secondary: {
      main: '#d5f0c9',
    },
    background: {
      default: '#fdfffc',
    },
  },
  ...defaultStyles,
})

export const backgroundDefaultDarkHex = '#303134'
export const zaaksysteemBackgroundColorHex = '#f7f6f4'
export const xxllncFooterColorHex = '#e5e5e5'
export const xxllncPrimaryBackgroundColorHex = '#333338'
export const primaryTerceraColor = 'rgb(232, 67, 147)'

export const darkTheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#262626',
    },
    secondary: {
      main: '#121212',
      contrastText: '#e3e0dc',
    },
    background: {
      default: backgroundDefaultDarkHex,
    },
  },
  ...defaultStyles,
})
