import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { useState } from 'react'
import { MagnifierIcon } from '../../atoms/magnifier-icon/MagnifierIcon.component'
import { MaterialIconButton } from '../../atoms/material-icon-button/MaterialIconButton.component'
import { ProjectLogo } from '../../molecules/project-logo/ProjectLogo.component'
import { MunicipalitiesSearch } from '../municipalities-search/MunicipalitiesSearch.component'
import { MunicipalitiesSearchDrawer } from '../../molecules/municipalities-search-drawer/MunicipalitiesSearchDrawer.component'
import { mainHeaderStyles as styles } from './mainHeader.styles'
import { useTranslation } from 'react-i18next'
import { MaterialNavLink } from '../../atoms/material-navlink/MaterialNavlink.component'
import { availableRoutes } from '../../../routers/availableRoutes'
import { useLocation } from 'react-router-dom'

/**
 * Renders the main header component
 * @returns the main header
 */
const MainHeader = (): JSX.Element => {
  const { t } = useTranslation()
  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = useState(false)

  const location = useLocation()
  const _styles = styles()

  return (
    <>
      <Box component={'div'} className={'header header--main'} sx={_styles.firstWrapper}>
        <Container>
          <Box>
            <Box
              display={_styles.secondWrapper.display}
              alignItems={_styles.secondWrapper.alignItems}
              justifyContent={_styles.secondWrapper.justifyContent}
            >
              <Box
                display={_styles.thirdWrapper.display}
                alignItems={_styles.thirdWrapper.alignItems}
              >
                <MaterialNavLink style={_styles.link} to={availableRoutes.HOME}>
                  <ProjectLogo />
                </MaterialNavLink>
              </Box>
              {location.pathname === availableRoutes.HOME && (
                <>
                  <MaterialIconButton
                    aria-label={t('a11y.labels.ZOOM_IN')}
                    role={'button'}
                    className='btn btn--icon'
                    sx={_styles.secondWrapper.searchIconButtonMobile}
                    onClick={() => setIsSearchDrawerOpen(true)}
                  >
                    <MagnifierIcon />
                  </MaterialIconButton>
                  <Box sx={_styles.secondWrapper.searchbarDesktopWrapper}>
                    <MunicipalitiesSearch
                      setIsSearchDrawerOpen={(value: boolean) => setIsSearchDrawerOpen(value)}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <MunicipalitiesSearchDrawer
        isOpen={isSearchDrawerOpen}
        onClose={() => setIsSearchDrawerOpen(false)}
      />
    </>
  )
}

export { MainHeader }
