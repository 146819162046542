import { SxProps } from '@mui/material'

export const terceraLogoStyles: SxProps = {
  height: {
    xs: 28,
    md: 38,
  },
  p: 1,
  pb: 1,
  mr: 1.5,
  img: {
    width: '100px',
  },
}
