import { Container } from '@mui/material'
import { Box } from '@mui/system'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MaterialButton } from '../../atoms/material-button/MaterialButton.component'
import { MaterialInfoIcon } from '../../atoms/material-info-icon/MaterialInfoIcon.component'
import { MaterialLink } from '../../atoms/material-link/MaterialLink.component'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { cookieNotificationStyles as styles } from './cookieNotification.style'
import { ICookieNotification } from './ICookieNotification.interface'
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders a cookie notification banner
 * @returns
 */
const CookieNotification = (props: ICookieNotification): JSX.Element | null => {
  const { t } = useTranslation()
  const cookieName = 'show_cookie_banner'
  const [showCookiePurposes, setShowCookiePurposes] = useState(false)
  const [hideCookieNotification, setHideCookieNotification] = useState(false)

  if (hideCookieNotification || localStorage.getItem(cookieName) === '0') {
    return null
  }

  const { currentTheme } = useContext(A11YContext)
  const _styles = styles({ theme: currentTheme })

  return (
    <Box sx={_styles.wrapper}>
      <Container>
        <Box sx={_styles.content}>
          <Box sx={_styles.infoWrapper}>
            <MaterialInfoIcon sx={_styles.icon} />
            <Box>
              <MaterialTypography sx={_styles.title} variant='h6'>
                {t('cookieNotification.TITLE', { host: props.host })}
              </MaterialTypography>
              <MaterialTypography sx={_styles.text}>
                {t('cookieNotification.DESCRIPTION', { host: props.host })}
              </MaterialTypography>
              {showCookiePurposes && (
                <MaterialTypography component='span' sx={_styles.text}>
                  <Box sx={_styles.secondaryTitle} component={'span'}>
                    {t('cookieNotification.purposes.TITLE')}
                  </Box>
                  <ul>
                    <li>{t('cookieNotification.purposes.FIRST', { host: props.host })}</li>
                    <li>{t('cookieNotification.purposes.SECOND')}</li>
                  </ul>
                </MaterialTypography>
              )}
              {!showCookiePurposes && (
                <MaterialLink
                  sx={_styles.link}
                  href='#'
                  onClick={() => setShowCookiePurposes(!showCookiePurposes)}
                >
                  {t('cookieNotification.purposes.SHOW_BUTTON_TEXT')}
                </MaterialLink>
              )}
            </Box>
          </Box>
          <Box>
            <MaterialButton
              href='#'
              sx={_styles.button}
              onClick={() => {
                setHideCookieNotification(true)
                localStorage.setItem(cookieName, '0')
              }}
            >
              {t('cookieNotification.CLOSE_BUTTON_TEXT')}
            </MaterialButton>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export { CookieNotification }
