import React, { useContext, useEffect } from 'react'
import { AxiosResponse } from 'axios'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import municipalityStore from '../../../contexts/MunicipalityStoreContext'
import planStore from '../../../contexts/PlanStoreContext'
import { IListOption } from '../../molecules/list-option/IListOption.interface'
import * as municipalityService from '../../../services/municipalities'
import * as planService from '../../../services/plans'
import { IMunicipalitiesSearch } from './IMunicipalitiesSearch.interface'
import { IMunicipality } from '../../../services/municipalities/IMunicipality.interface'
import { toast } from 'react-toastify'
import { IPlan } from '../../../services/plans/IPlan.interface'
import { MaterialTooltip } from '../../atoms/material-tooltip/MaterialTooltip.component'
import { MaterialTextfield } from '../../atoms/material-textfield/MaterialTextfield.component'
import { municipalitiesSearchStyles as styles } from './municipalitiesSearch.styles'
import { A11YContext } from '../../../contexts/A11YContext'
import { Paper } from '@mui/material'
import { useDebounce } from 'use-debounce'

/**
 * Renders a searchbar component to search by plannumber or municipality
 * @param props
 * @returns Renders a searchbar component
 */
const MunicipalitiesSearch = observer((props: IMunicipalitiesSearch): JSX.Element | null => {
  const { currentTheme } = useContext(A11YContext)

  const [inputValue, setInputValue] = React.useState<string>('')
  const [idnNumber] = useDebounce(inputValue, 1000)
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [selectableOptions, setSelectableOptions] = React.useState<readonly IListOption[]>([])

  const { t } = useTranslation()
  const _styles = styles({ theme: currentTheme })
  const inputRef = React.createRef<HTMLDivElement>()
  const loading = isDropdownOpen && selectableOptions.length === 0

  const MIN_INPUT_LENGTH = 3
  const IMRO_PREFIX = 'NL.IMRO.'

  /**
   * Fetches municipalities by a given search term
   * @returns void
   */
  const fetchMunicipalities = async (): Promise<IListOption[] | []> => {
    try {
      const results: IListOption[] = []
      const fetchResult: AxiosResponse<IMunicipality[]> =
        await municipalityService.fetchMunicipalities()

      for (let index = 0; index < fetchResult.data.length; index = index + 1) {
        let shouldPush = true
        if (fetchResult.data[index].naam.toLowerCase().startsWith('demo')) {
          shouldPush = false
        }
        if (fetchResult.data[index].naam.toLowerCase() === 'acceptance') {
          shouldPush = false
        }
        if (fetchResult.data[index].naam.toLowerCase() === 'testgemeente') {
          shouldPush = false
        }

        if (shouldPush) {
          results.push({
            label: fetchResult.data[index].naam,
            value: fetchResult.data[index].cbs,
          })
        }
      }

      return results
    } catch (err) {
      toast(t('primaryHeader.searchBar.FETCH_ERROR'), {
        position: 'bottom-left',
        type: 'error',
      })
    }

    return []
  }

  /**
   * Fetches the plans for a given municipality
   * @returns void
   */
  const fetchPlansForMunicipality = async (
    selectedOption: IListOption | string,
  ): Promise<IPlan[] | []> => {
    try {
      const fetchResult: AxiosResponse<IPlan[]> = await planService.fetchPlansForMunicipality(
        selectedOption,
      )

      return planStore.normalizePlans(fetchResult)
    } catch (err) {
      toast(t('primaryHeader.searchBar.FETCH_ERROR'), {
        position: 'bottom-left',
        type: 'error',
      })
    }

    return []
  }

  /**
   * Fetches a plan by a given idn number
   * @returns void
   */
  const fetchPlanByIdnNumber = async (idnNumber: string): Promise<IPlan[] | []> => {
    try {
      const fetchResult: AxiosResponse<IPlan[]> = await planService.fetchPlanByIdnNumber(idnNumber)

      return planStore.normalizePlans(fetchResult)
    } catch (err) {
      toast(t('primaryHeader.searchBar.FETCH_ERROR'), {
        position: 'bottom-left',
        type: 'error',
      })
    }

    return []
  }

  useEffect(() => {
    const handle = async () => {
      planStore.setIsLoading(true)

      const planSearchResults = await fetchPlanByIdnNumber(idnNumber)
      planStore.setPlanSearchResults(planSearchResults)

      planStore.setIsLoading(false)
    }

    if (idnNumber.toLowerCase().startsWith(IMRO_PREFIX.toLowerCase()) && idnNumber.length > 10) {
      handle()

      if (props.onClose) {
        props.onClose()
      }
    }
  }, [idnNumber])

  useEffect(() => {
    let active = true

    if (!loading) {
      return undefined
    }

    (async () => {
      if (active && inputValue.length >= MIN_INPUT_LENGTH && !inputValue.startsWith('NL.')) {
        const municipalities = await fetchMunicipalities()
        setSelectableOptions(municipalities)
      }
    })()

    return () => {
      active = false
    }
  }, [loading, inputValue])

  useEffect(() => {
    if (!isDropdownOpen) {
      setSelectableOptions([])
    }
  }, [isDropdownOpen])

  useEffect(() => {
    if (!municipalityStore.selectedMunicipality) {
      return
    }

    inputRef.current?.blur()

    const handle = async () => {
      planStore.setIsLoading(true)

      const searchResults = await fetchPlansForMunicipality(municipalityStore.selectedMunicipality)
      planStore.setPlanSearchResults(searchResults)

      planStore.setIsLoading(false)
    }

    handle()

    if (props.setIsSearchDrawerOpen) {
      props.setIsSearchDrawerOpen(false)
    }
  }, [municipalityStore.selectedMunicipality])

  return (
    <>
      <MaterialTooltip
        arrow
        title={t('primaryHeader.searchBar.MIN_CHARS_TEXT', { number: MIN_INPUT_LENGTH })}
        open={loading && inputValue.length <= MIN_INPUT_LENGTH}
      >
        <Autocomplete
          className='searchbar'
          role={'searchbox'}
          sx={_styles.root}
          open={isDropdownOpen && selectableOptions.length > 0}
          onOpen={() => {
            setIsDropdownOpen(true)
          }}
          onClose={() => {
            setIsDropdownOpen(false)
            setInputValue('')
            setSelectableOptions([])
          }}
          onBlur={() => {
            setIsDropdownOpen(false)
            setInputValue('')
            setSelectableOptions([])
          }}
          onChange={(event, selectedMunicipality) => {
            event.preventDefault()
            if (!selectedMunicipality) {
              return null
            }

            municipalityStore.setSelectedMunicipality(selectedMunicipality as IListOption)

            setIsDropdownOpen(false)
            setInputValue('')
            setSelectableOptions([])
          }}
          ListboxProps={{ style: _styles.listbox }}
          options={selectableOptions}
          loading={loading}
          popupIcon={null}
          loadingText={t('primaryHeader.searchBar.LOADING_TEXT')}
          noOptionsText={t('primaryHeader.searchBar.NO_OPTIONS_TEXT')}
          clearIcon={null}
          inputValue={inputValue}
          isOptionEqualToValue={(option: IListOption, value: IListOption) =>
            option.label === value.label
          }
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setInputValue('')
              return
            }
            if (reason !== 'reset') {
              setInputValue(value)
            }
          }}
          PaperComponent={({ children }) => <Paper sx={_styles.paper}>{children}</Paper>}
          renderInput={(params) => (
            <MaterialTextfield
              {...params}
              label={t('primaryHeader.searchBar.PLACEHOLDER')}
              inputRef={inputRef}
              sx={_styles.input}
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                autoFocus: true
              }}
            />
          )}
        />
      </MaterialTooltip>
    </>
  )
})

export { MunicipalitiesSearch }
