import CallMadeIcon from '@mui/icons-material/CallMade'
import { SvgIconProps } from '@mui/material'

/**
 * Renders a call made icon in material style
 * @param props
 * @returns A call made icon
 */
const MaterialCallMadeIcon = (props: SvgIconProps): JSX.Element => {
  return <CallMadeIcon {...props} />
}

export { MaterialCallMadeIcon }
