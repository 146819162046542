import { SxProps } from '@mui/material';
import { IA11Y } from '../../../interfaces/A11Y';
import { availibleThemes } from '../../../config/material-theme.config';

export const emailSupportButtonStyles = (a11y: Partial<IA11Y>): SxProps => ({
    display: 'flex',
    span: {
        color: a11y.theme === availibleThemes.LIGHT ? 'primary' : 'rgba(255, 255, 255, 1)'
    },
    borderColor: a11y.theme === availibleThemes.LIGHT ? 'primary' : 'rgba(255, 255, 255, 1)',
    '.MuiSvgIcon-root': {
        width: 20,
        marginRight: 1
        },
        color: a11y.theme === availibleThemes.LIGHT ? 'primary' : 'rgba(255, 255, 255, 1)',
        '.MuiButtonBase-root': {
        display: { xs: 'flex', sm: 'inline-flex' },
    }    
})
