import MapIcon from '@mui/icons-material/Map'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single map icon
 * @param props
 * @returns A single map icon
 */
const MaterialMapIcon = (props: SvgIconProps): JSX.Element => {
  return <MapIcon {...props} />
}

export { MaterialMapIcon }
