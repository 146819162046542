import { Drawer, DrawerProps } from '@mui/material'

/**
 * Renders a drawer component
 * @param props
 * @returns A drawer component
 */
const MaterialDrawer = (props: DrawerProps): JSX.Element => {
  const { children, ...rest } = props
  return <Drawer {...rest}>{children}</Drawer>
}

export { MaterialDrawer }
