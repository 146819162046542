import { SxProps } from '@mui/material'
import { availibleThemes, fontSizes, headerFontFamily } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const wrapperStyles: SxProps = {
  pt: {
    xs: 3,
    md: 5,
  },
  pb: {
    xs: 3,
    md: 5,
  },
  px: {
    xs: 1,
    md: 0,
  },
}

const headingWrapperStyles: SxProps = {
  display: 'flex',
  marginTop: {
    xs: 0,
    md: 0.3,
  },
  alignItems: {
    xs: 'flex-start',
    md: 'center',
  },
}

const iconStyles = (a11y: Partial<IA11Y>): SxProps => ({
  color: a11y.theme === availibleThemes.LIGHT ? 'primary.main' : 'secondary.contrastText',
  fontSize: {
    xs: 20,
    md: 22,
  },
  mb: {
    xs: 0.1,
    md: 0,
  },
  mt: {
    xs: 0.2,
    md: 0,
  },
})

const titleStyles: SxProps = {
  marginLeft: {
    xs: 0.8,
    md: 1,
  },
  fontSize: {
    xs: fontSizes.default.md,
    md: fontSizes.bigger.md,
  },
  fontFamily: headerFontFamily,
  color: 'secondary.contrastText',
  fontWeight: '500',
}

export const pageHeaderStyles = (a11y: IA11Y) => {
  return {
    wrapper: wrapperStyles,
    headingWrapper: headingWrapperStyles,
    icon: iconStyles(a11y),
    title: titleStyles,
  }
}
