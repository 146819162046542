import { SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { availibleThemes } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const buttonStyles: SxProps = {
  p: 0.4,
}

const iconStyles = (a11y: Partial<IA11Y>): SxProps => ({
  fontSize: {
    xs: 12,
    md: 14,
  },
  color: a11y.theme === availibleThemes.LIGHT ? grey[700] : 'secondary.contrastText',
})

export const themeTogglerStyles = (i11y: IA11Y) => {
  return {
    button: buttonStyles,
    icon: iconStyles(i11y),
  }
}
