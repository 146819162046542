import { MaterialButton } from '../../atoms/material-button/MaterialButton.component'
import { useTranslation } from 'react-i18next';
import { MaterialEnvelopeIcon } from '../../atoms/material-envelope-icon/MaterialEnvelopeIcon.component';
import { emailSupportButtonStyles as styles } from './emailSupportButton.styles';
import { useContext } from 'react';
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders a a button that when clicked, opens a email url to mail the support department
 */
const EmailSupportButton = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { currentTheme } = useContext(A11YContext)
    const _styles = styles({ theme: currentTheme })

    return (
        <MaterialButton 
            sx={_styles} 
            component={'a'} 
            href={'mailto:support@tercera.nl'} 
            variant='outlined'
        >
            <><MaterialEnvelopeIcon /> <span>{t('suggestionButtons.EMAIL_SUPPORT_LABEL')}</span></>
        </MaterialButton>
    )
}

export { EmailSupportButton }
