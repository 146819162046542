import { CircularProgress, CircularProgressProps } from '@mui/material'

/**
 * Renders a loader icon
 * @param props
 * @returns Renders a loader icon
 */
const MaterialCircularProgress = (props: CircularProgressProps): JSX.Element => {
  return <CircularProgress {...props} />
}

export { MaterialCircularProgress }
