import axios, { AxiosResponse } from 'axios'
import { IListOption } from '../../components/molecules/list-option/IListOption.interface'
import { API } from './api'
import { IPlan } from './IPlan.interface'

const planAxios = axios.create()

/**
 * Fetches all plans for a given municipality
 * @returns A list of all plans for the given municipality
 */
export const fetchPlansForMunicipality = (
  municipality: string | IListOption,
): Promise<AxiosResponse<IPlan[]>> => {
  const value = typeof municipality === 'string' ? municipality : municipality.value

  if (!value) {
    throw new Error('Value of undefined given')
  }

  const url = API.fetchPlansForMunicipality.replace('{{cbs}}', value)
  return planAxios.get(url)
}

/**
 * Fetches plans by a given date
 * @returns A list of all plans for a given date
 */
export const fetchPlansByDate = (date: Date): Promise<AxiosResponse<IPlan[]>> => {
  if (!date) {
    throw new Error('No date given')
  }

  const url = API.fetchPlansByDate(date.toLocaleDateString('en-EN'))
  return planAxios.get(url)
}

/**
 * Fetches a plan by a given idn number
 * @returns A list of all plans that match the idn number
 */
export const fetchPlanByIdnNumber = (idnNumber: string): Promise<AxiosResponse<IPlan[]>> => {
  if (!idnNumber) {
    throw new Error('No IDN number given')
  }

  const url = API.fetchPlanByIdnNumber(idnNumber)
  return planAxios.get(url)
}
