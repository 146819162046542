import { Box, Container } from '@mui/system'
import { useContext } from 'react'
import { A11YContext } from '../../../contexts/A11YContext'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { IPageHeader } from './IPageHeader.interface'
import { pageHeaderStyles as styles } from './pageHeader.styles'

/**
 * Renders a default page header
 * @param props
 * @returns
 */
const PageHeader = (props: IPageHeader): JSX.Element => {
  const { currentTheme } = useContext(A11YContext)
  const _styles = styles({ theme: currentTheme })

  const { iconComponent: IconComponent } = props

  return (
    <Container>
      <Box className={'header header--default'} sx={_styles.wrapper}>
        <Box sx={_styles.headingWrapper}>
          {IconComponent && <IconComponent sx={_styles.icon} />}
          <MaterialTypography component={'h2'} sx={_styles.title}>
            <>{props.title}</>
          </MaterialTypography>
        </Box>
      </Box>
    </Container>
  )
}

export { PageHeader }
