import { SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { availibleThemes, fontSizes } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const themeTogglerWrapperStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: {
    xs: 1,
    md: 0,
  },
}

const zoomLevelTogglerStyles: SxProps = {
  padding: 0,
  display: {
    md: 'none',
  },
  backgroundColor: 'transparant',
  marginRight: '10px',
}

const loginDrawerTogglerStyles: SxProps = {
  display: { md: 'none' },
  fontSize: fontSizes.default,
  marginRight: 1,
}

const themeTogglerStyles: SxProps = {
  display: {
    md: 'none',
  },
  mr: {
    xs: 0.5,
    md: 1,
  },
}

export const metaHeaderStyles = (a11y: IA11Y): SxProps => {
  return {
    bgcolor: {
      xs: a11y.theme === availibleThemes.LIGHT ? grey[100] : 'primary.main',
    },
    borderBottom: a11y.theme === availibleThemes.LIGHT ? 0 : 1,
    borderColor: a11y.theme === availibleThemes.LIGHT ? 'transparent' : 'secondary.contrastText',
    borderWidth: a11y.theme === availibleThemes.LIGHT ? '0px' : '1px',
    '.header__navbar': {
      py: {
        xs: 1.7,
        md: 0,
      },
      display: {
        xs: 'none',
        md: 'block',
      },
    },
    '.a11y__wrapper': {
      ...themeTogglerWrapperStyles,
    },
    '.a11y__theme-toggler': {
      ...themeTogglerStyles,
    },
    '.a11y__zoom-toggler': {
      ...zoomLevelTogglerStyles,
    },
    '.a11y__login-toggler': {
      ...loginDrawerTogglerStyles,
    },
    '.a11y__account-icon-wrapper': {
      marginRight: 1.2,
      marginTop: 0.5,
      '.MuiSvgIcon-root': {
        fontSize: 16,
        marginLeft: 0.8,
        color: a11y.theme === availibleThemes.LIGHT ? grey[700] : 'secondary.contrastText',
      },
    },
  }
}
