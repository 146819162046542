import { Box } from '@mui/material'
import { Container } from '@mui/system'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { A11YContext } from '../../../contexts/A11YContext'
import { MaterialArticleIcon } from '../../atoms/material-article-icon/MaterialArticleIcon.component'
import { PageHeader } from '../../molecules/page-header/PageHeader.component'
import { TermsAndConditions } from '../../molecules/terms-and-conditions/TermsAndConditions.component'
import { BaseTemplate } from '../../templates/base-template/BaseTemplate.component'
import { termsAndConditionsPageStyles as styles } from './termsAndConditionsPage.styles'

/**
 * Renders the terms and conditions page
 * @returns
 */
const TermsAndConditionsPage = () => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)
  const _styles = styles({ theme: currentTheme })

  const pageTitle: string = t('pages.termsAndConditions.FIRST_TITLE')

  return (
    <div className='page page--terms-and-conditions'>
      <BaseTemplate>
        <PageHeader title={pageTitle} iconComponent={MaterialArticleIcon} />
        <Box sx={_styles.contentContainer}>
          <Container>
            <TermsAndConditions />
          </Container>
        </Box>
      </BaseTemplate>
    </div>
  )
}

export { TermsAndConditionsPage }
