import PhoneIcon from '@mui/icons-material/Phone'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single phone icon
 * @param props
 * @returns A single phone icon
 */
const MaterialPhoneIcon = (props: SvgIconProps): JSX.Element => {
  return <PhoneIcon {...props} />
}

export { MaterialPhoneIcon }
