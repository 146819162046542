import { runInAction, makeAutoObservable } from 'mobx'
import { IListOption } from '../components/molecules/list-option/IListOption.interface'

/**
 * MunicipalityStore
 */
class MunicipalityStore {
  selectedMunicipality: IListOption | string
  municipalitySearchResults: IListOption[]

  /**
   * Constructor
   */
  constructor() {
    makeAutoObservable(this)

    this.selectedMunicipality = ''
    this.municipalitySearchResults = []
  }

  /**
   * Setter for the selectedMunicipality value
   * @param value The value to set
   */
  setSelectedMunicipality(value: IListOption | string) {
    runInAction(() => {
      this.selectedMunicipality = value
    })
  }

  /**
   * Setter for the municipality search results
   * @param value The value to set
   */
  setMunicipalitySearchResults(value: IListOption[]) {
    runInAction(() => {
      this.municipalitySearchResults = value
    })
  }
}

export default MunicipalityStore
