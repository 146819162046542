import { Chip, ChipProps } from '@mui/material'

/**
 * Renders a chip component in material style
 * @param props
 * @returns A chip component
 */
const MaterialChip = (props: ChipProps): JSX.Element => {
  return <Chip {...props} />
}

export { MaterialChip }
