import { SxProps } from '@mui/material'

const paperStyles: SxProps = {
  width: '75%',
  backgroundColor: 'background.default',
}

const wrapperStyles: SxProps = {
  borderBottom: 1,
  borderColor: 'lightgray',
}

const boxStyles: SxProps = {
  px: {
    xs: 1,
  },
  py: {
    xs: 1.5,
  },
}

export const municipalitiesSearchDrawerStyles = {
  paper: paperStyles,
  wrapper: wrapperStyles,
  box: boxStyles,
}
