import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDossier } from '../../../services/dossiers/IDossier.interface'
import { MaterialClickAwayListener } from '../../atoms/material-click-away-listener/MaterialClickAwayListener.component'
import { MaterialTooltip } from '../../atoms/material-tooltip/MaterialTooltip.component'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { fetchDossierStatus } from '../../organisms/plan-results/dossier.helpers'
import { dossierTooltipStyles as _styles } from './dossierTooltip.styles'
import { IDossierTooltip } from './IDossierTooltip.interface'

/**
 * Renders the content of the dossier tooltip
 * @returns - The content that is being displayed in the dossier tooltip
 */
const DossierTooltipContent = (props: IDossier) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box sx={_styles.column}>
        <MaterialTypography sx={_styles.text}>
          {t('dossiers.status.TOOLTIP_STATUS_PREFIX')}:
        </MaterialTypography>
        <MaterialTypography sx={_styles.text}>{props.status}</MaterialTypography>
      </Box>
      <Box sx={_styles.column}>
        <MaterialTypography sx={_styles.text}>
          {t('dossiers.status.TOOLTIP_IDENTIFICATION_PREFIX')}:
        </MaterialTypography>
        <MaterialTypography sx={_styles.text}>{props.dossierNummer}</MaterialTypography>
      </Box>
    </Box>
  )
}

/**
 * Renders a button that when clicked, shows the dossier tooltip
 * @returns - A information button that displays a tooltip when clicked
 */
const withDossierTooltip = (
  ButtonComponent: React.FC,
  tooltipPlacement: 'left' | 'right' | 'top' | 'bottom',
): ((props: IDossierTooltip) => JSX.Element) => {
  const WithDossierTooltip = (props: IDossierTooltip): JSX.Element => {
    const { idn } = props

    /**
     * Custom hooks
     */
    const { t } = useTranslation()

    /**
     * States
     */
    const [tooltipData, setTooltipData] = useState<IDossier | null>(null)
    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)

    /**
     * Effect hooks
     */
    useEffect(() => {
      const handle = async () => {
        const result = await fetchDossierStatus(idn, t('dossiers.status.FETCH_ERROR'))

        if (result && result.data) {
          setTooltipData(result.data)
          setIsTooltipOpen(true)
        }
      }

      if (isTooltipOpen) {
        handle()
      }
    }, [isTooltipOpen])

    /**
     * Render
     */
    return (
      <MaterialClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
        <div>
          <MaterialTooltip
            PopperProps={{
              disablePortal: true,
            }}
            placement={tooltipPlacement}
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            open={isTooltipOpen}
            onClose={(event) => {
              event.preventDefault()
              setIsTooltipOpen(false)
            }}
            title={tooltipData ? <DossierTooltipContent {...tooltipData} /> : ''}
            onOpen={async (event) => {
              event.preventDefault()
            }}
            arrow={true}
          >
            <div
              className='button-wrapper'
              onClick={(event) => {
                event.preventDefault()
                setIsTooltipOpen(true)
              }}
            >
              <ButtonComponent />
            </div>
          </MaterialTooltip>
        </div>
      </MaterialClickAwayListener>
    )
  }

  return WithDossierTooltip
}

export { DossierTooltipContent, withDossierTooltip }
