import { SxProps } from '@mui/material'
import {
  availibleThemes,
  fontSizes,
  xxllncPrimaryBackgroundColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const wrapperStyles: SxProps = {
  display: 'block',
}

const stackStyles: SxProps = {
  spacing: { xs: 0, md: 2 },
}

const boxStyles: SxProps = {
  padding: 1,
  display: 'flex',
}

const iconStyles = (a11y: Partial<IA11Y>): SxProps => ({
  marginRight: 1,
  color:
    a11y.theme === availibleThemes.LIGHT
      ? xxllncPrimaryBackgroundColorHex
      : 'secondary.contrastText',
  fontSize: {
    xs: 14,
    md: fontSizes.bigger.xs,
  },
})

const linkStyles: SxProps = {
  textDecoration: 'none',
  color: 'secondary.contrastText',
  lineHeight: 1,
  textAlign: 'left',
  fontSize: fontSizes.default.xs,
}

export const contactInfoStyles = (a11y: IA11Y) => ({
  wrapper: wrapperStyles,
  stack: stackStyles,
  box: boxStyles,
  icon: iconStyles(a11y),
  link: linkStyles,
})
