import { Box } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { A11YContext } from '../../../contexts/A11YContext'
import { EmptyTemplate } from '../../templates/empty-template/EmptyTemplate.component'
import { notFoundPageStyles as styles } from './notFoundPage.styles'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { SuggestionButtonGroup } from '../../organisms/suggestion-button-group/SuggestionButtonGroup.component'

/**
 * Renders the 404 not found page
 */
const NotFoundPage = () => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)
  const _styles = styles({ theme: currentTheme })

  return (
    <div className='page page--not-found'>
      <EmptyTemplate>
        <Box sx={_styles.contentContainer}>
            <Box>
                <Box sx={_styles.titleBoxStyles}>
                    <MaterialTypography component={'h2'}>
                        {t('pages.notFound.TITLE')}
                    </MaterialTypography>
                </Box>
                <SuggestionButtonGroup />
            </Box>
        </Box>
      </EmptyTemplate>
    </div>
  )
}

export { NotFoundPage }
