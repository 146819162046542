import { Fab, FabProps } from '@mui/material'

/**
 * Renders a fab component in material style
 * @param props
 * @returns A fab component
 */
const MaterialFab = (props: FabProps): JSX.Element => {
  return <Fab {...props} />
}

export { MaterialFab }
