import Typography from '@mui/material/Typography'
import { IMaterialTypography } from './IMaterialTypography.interface'

/**
 * Renders typography component
 * @param props
 * @returns Renders typography wrapper
 */
const MaterialTypography = (props: IMaterialTypography): JSX.Element => {
  const { children, ...rest } = props
  return <Typography {...rest}>{children}</Typography>
}

export { MaterialTypography }
