import { IMaterialBrightnessIcon } from './IMaterialBrightnessIcon.interface'
import { Brightness4, Brightness7 } from '@mui/icons-material'

/**
 * Renders a single brightness icon in material-ui style
 * @param props
 * @returns A single brightness icon in material-ui style
 */
const MaterialBrightnessIcon = (props: IMaterialBrightnessIcon): JSX.Element | null => {
  const { level, ...rest } = props

  if (level === 4) {
    return <Brightness4 {...rest} className={'icon icon--brightness-4'} />
  }

  if (level === 7) {
    return <Brightness7 {...rest} className={'icon icon--brightness-7'} />
  }

  return null
}

export { MaterialBrightnessIcon }
