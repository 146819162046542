import { BackToHomepageButton } from '../../molecules/back-to-homepage-button/BackToHomepageButton.component';
import { useTranslation } from 'react-i18next';
import { EmailSupportButton } from '../../molecules/email-support-button/EmailSupportButton.component';
import { Box } from '@mui/material';
import { suggestionButtonGroupStyles as styles } from './suggestionButtonGroup.styles';
import { A11YContext } from '../../../contexts/A11YContext'
import { useContext } from 'react';

/**
 * Renders two buttons so a user can choose what to do next
 * @returns a back to the homepage button and a contact support button
 */
const SuggestionButtonGroup = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { currentTheme } = useContext(A11YContext)
    const _styles = styles({ theme: currentTheme })
      
    return (
        <Box sx={_styles}>
            <BackToHomepageButton />
            <span className='divider'>{t('suggestionButtons.SEPARATION_TEXT')}</span>
            <EmailSupportButton />
        </Box>
    )
}

export { SuggestionButtonGroup }
