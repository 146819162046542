import { SxProps } from '@mui/material'
import { availibleThemes, xxllncFooterColorHex } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const loaderWrapperStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: 5,
  marginTop: 2,
}

const loaderStyles: SxProps = {
  width: 20,
  height: 20,
}

const oddRowStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor: {
    md: a11y.theme === availibleThemes.LIGHT ? xxllncFooterColorHex : 'primary.main',
  },
  borderRadius: 1,
})

const evenRowStyles: SxProps = {}

export const planResultStyles = (a11y: IA11Y) => {
  return {
    loaderWrapper: loaderWrapperStyles,
    loader: loaderStyles,
    oddRows: oddRowStyles(a11y),
    evenRows: evenRowStyles,
  }
}
