import SearchIcon from '@mui/icons-material/Search'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single magnifier icon
 * @param props
 * @returns A single magnifier icon
 */
const MagnifierIcon = (props: SvgIconProps): JSX.Element => {
  return <SearchIcon {...props} />
}

export { MagnifierIcon }
