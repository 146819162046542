import BookIcon from '@mui/icons-material/Book'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single book icon
 * @param props
 * @returns A single book icon
 */
const MaterialBookIcon = (props: SvgIconProps): JSX.Element => {
  return <BookIcon {...props} />
}

export { MaterialBookIcon }
