import { InputAdornment, InputAdornmentProps } from '@mui/material'

/**
 * Renders a input adorment component for a given position
 * @param props
 * @returns A input adorment component
 */
const MaterialInputAdorment = (props: InputAdornmentProps): JSX.Element => {
  const { children, ...rest } = props

  return <InputAdornment {...rest}>{children}</InputAdornment>
}

export { MaterialInputAdorment }
