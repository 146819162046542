import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { ContactInfo } from '../../molecules/contact-info/ContactInfo.component'
import { Copyright } from '../../molecules/copyright/Copyright.component'
import { TerceraLogo } from '../../molecules/tercera-logo/TerceraLogo.component'
import { mainFooterStyles as styles } from './mainFooter.styles'
import { useContext } from 'react'
import { availibleThemes } from '../../../config/material-theme.config'
import { A11YContext } from '../../../contexts/A11YContext'
import { XxllncLogo } from '../../molecules/xxllnc-logo/XxllncLogo.component'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { useTranslation } from 'react-i18next'
import { MaterialNavLink } from '../../atoms/material-navlink/MaterialNavlink.component'
import { availableRoutes } from '../../../routers/availableRoutes'
import { MaterialLink } from '../../atoms/material-link/MaterialLink.component'

/**
 * Renders the main footer component
 * @returns the main footer
 */
const MainFooter = (): JSX.Element => {
  const { currentTheme } = useContext(A11YContext)
  const { t } = useTranslation()

  const _styles = styles({ theme: currentTheme })

  return (
    <>
      <Box component='div' className='footer footer--main'>
        <Box sx={_styles.topFooter}>
          <Container>
            <Box component='div' sx={_styles.logoWrapper}>
              <TerceraLogo type={currentTheme === availibleThemes.LIGHT ? 'light' : 'dark'} />
            </Box>
            <Box component='div' sx={_styles.contactInfoWrapper}>
              <ContactInfo />
            </Box>
          </Container>
        </Box>
      </Box>
      <Box sx={_styles.subfooterFixedContainer}>
        <Container>
          <Box component='div' sx={_styles.subFooterWrapper}>
            <Box component='div' sx={_styles.copyrightWrapper}>
              <Box>
                <Copyright />
              </Box>
              <Box component={'span'} sx={_styles.seperator}>
                •
              </Box>
              <Box display={'flex'}>
                <MaterialTypography sx={_styles.text}>
                  {t('footer.copyright.PART_OF_TEXT')}
                </MaterialTypography>
                <XxllncLogo type={currentTheme === availibleThemes.DARK ? 'dark' : 'light'} />
              </Box>
            </Box>
            <Box sx={_styles.navigationWrapper} component={'div'}>
              <MaterialNavLink
                to={availableRoutes.TERMS_AND_CONDITIONS}
                sx={_styles.link}
                style={{ textDecoration: 'none' }}
              >
                {t('footer.links.TERMS_AND_CONDITIONS_LINK_TEXT')}
              </MaterialNavLink>
              <MaterialLink
                href='https://www.tercera.nl/support/'
                sx={_styles.link}
                target='_blank'
              >
                {t('footer.links.SUPPORT_LINK_TEXT')}
              </MaterialLink>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export { MainFooter }
