import { Box, Container } from '@mui/material'
import { MaterialDrawer } from '../../atoms/material-drawer/MaterialDrawer.component'
import { MunicipalitiesSearch } from '../../organisms/municipalities-search/MunicipalitiesSearch.component'
import { IMunicipalitiesSearchDrawer } from './IMunicipalitiesSearchDrawer.interface'
import { municipalitiesSearchDrawerStyles as styles } from './municipalitiesSearchDrawer.styles'

/**
 * Renders a drawer with a municipality searchbar
 * @param props
 * @returns A drawer component
 */
const MunicipalitiesSearchDrawer = (props: IMunicipalitiesSearchDrawer): JSX.Element => {
  return (
    <MaterialDrawer
      role={'dialog'}
      className={'drawer drawer--search'}
      onClose={props.onClose}
      open={props.isOpen}
      PaperProps={{ sx: styles.paper }}
    >
      <Box sx={styles.wrapper}>
        <Container>
          <Box sx={styles.box}>
            <MunicipalitiesSearch focusTextfield={props.isOpen} onClose={props.onClose} />
          </Box>
        </Container>
      </Box>
    </MaterialDrawer>
  )
}

export { MunicipalitiesSearchDrawer }
