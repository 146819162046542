import { SxProps } from '@mui/material'

export const emptyTemplateStyles: SxProps = {
  backgroundColor: 'background.primary',
  margin: 0,
  padding: 0,
  content: {
    margin: 0,
    padding: 0
  }
}
