import { SxProps } from '@mui/material'
import { availibleThemes, fontSizes, xxllncFooterColorHex } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const wrapperStyles: SxProps = {
  position: 'fixed',
  width: '100%',
  bottom: 0,
  backgroundColor: 'secondary.contrastText',
  color: 'defaults.white',
  py: 2,
}

const contentStyles: SxProps = {
  display: {
    xs: 'block',
    md: 'flex',
  },
  px: {
    xs: 1.2,
    md: 0,
  },
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  fontSize: fontSizes.default.xs,
}

const titleStyles = (a11y: IA11Y): SxProps => ({
  color: a11y.theme === availibleThemes.DARK ? 'rgba(0, 0, 0, 1)' : xxllncFooterColorHex,
  fontSize: {
    xs: fontSizes.default.md - 2,
    md: fontSizes.default.md,
  },
  marginBottom: {
    xs: 0.5,
    md: 0.3,
  },
  display: 'block',
})

const textStyles = (a11y: IA11Y): SxProps => ({
  color: a11y.theme === availibleThemes.DARK ? 'rgba(0, 0, 0, 1)' : xxllncFooterColorHex,
  fontSize: {
    xs: fontSizes.default.xs - 2,
    md: fontSizes.default.xs,
  },
  marginBottom: {
    xs: 1,
    md: 1.5,
  },
  display: 'block',
  ul: {
    paddingLeft: 1.7,
    marginBottom: 0,
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
})

const buttonStyles = (a11y: IA11Y): SxProps => ({
  color: a11y.theme === availibleThemes.DARK ? 'rgba(0, 0, 0, 1)' : xxllncFooterColorHex,
  padding: {
    xs: 0,
  },
  marginTop: {
    xs: 1,
    md: 0,
  },
})

const linkStyles = (a11y: IA11Y): SxProps => ({
  color: a11y.theme === availibleThemes.DARK ? 'rgba(0, 0, 0, 1)' : xxllncFooterColorHex,
  textDecoration: 'none',
  display: 'block',
  fontSize: {
    xs: fontSizes.default.xs - 2,
    md: fontSizes.default.xs,
  },
  marginTop: 1,
})

const iconStyles: SxProps = {
  color: xxllncFooterColorHex,
  fontSize: {
    xs: fontSizes.default.md,
    md: fontSizes.bigger.md,
  },
  marginBottom: {
    xs: 1,
    md: 0,
  },
  marginTop: {
    xs: 0.3,
  },
  marginRight: {
    md: 1.5,
  },
}

const infoWrapperStyles: SxProps = {
  display: {
    md: 'flex',
  },
}

const secondaryTitleStyles: SxProps = {
  marginTop: 1,
  display: 'block',
}

export const cookieNotificationStyles = (a11y: IA11Y) => ({
  wrapper: wrapperStyles,
  content: contentStyles,
  title: titleStyles(a11y),
  text: textStyles(a11y),
  button: buttonStyles(a11y),
  link: linkStyles(a11y),
  icon: iconStyles,
  infoWrapper: infoWrapperStyles,
  secondaryTitle: secondaryTitleStyles,
})
