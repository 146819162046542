import { SxProps } from '@mui/material'

const firstWrapperStyles: SxProps = {
  bgcolor: {
    xs: 'primary.main',
  },
  py: {
    xs: 3,
  },
}

const secondWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const thirdWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
}

const searchIconButtonMobileStyles = {
  mr: 1,
  color: 'white',
  display: {
    md: 'none',
  },
  '.MuiSvgIcon-root': {
    width: 22,
    height: 22,
  },
}

const searchbarDesktopWrapperStyles: SxProps = {
  display: {
    xs: 'none',
    md: 'block',
  },
}

const navigationIconButtonMobileStyles = {
  mr: 1,
  color: 'white',
  display: {
    md: 'none',
  },
  '.MuiSvgIcon-root': {
    width: 22,
    height: 22,
  },
}

const linkStyles = {
  textDecoration: 'none',
}

export const mainHeaderStyles = () => ({
  firstWrapper: firstWrapperStyles,
  secondWrapper: {
    ...secondWrapperStyles,
    searchIconButtonMobile: searchIconButtonMobileStyles,
    searchbarDesktopWrapper: searchbarDesktopWrapperStyles,
  },
  thirdWrapper: {
    ...thirdWrapperStyles,
    navigationIconButtonMobile: navigationIconButtonMobileStyles,
  },
  link: linkStyles,
})
