import { Box } from '@mui/material'
import { Image } from '../../atoms/image/Image.component'
import { MaterialLink } from '../../atoms/material-link/MaterialLink.component'
import { terceraLogoStyles as _styles } from './terceraLogo.styles'

/**
 * Renders a tercera logo component to display in the footer
 * @param props
 * @returns Renders a tercera logo component
 */
const TerceraLogo = (props: { type: 'light' | 'dark' }): JSX.Element => {
  return (
    <Box sx={_styles}>
      <MaterialLink href={'https://www.tercera.nl/'} target='_blank'>
        <Image
          className='logo logo--tercera'
          width={100}
          height={35}
          alt='logo-tercera'
          src={
            props.type === 'dark'
              ? '/assets/images/logo-tercera-dark.png'
              : '/assets/images/logo-tercera.png'
          }
        />
      </MaterialLink>
    </Box>
  )
}

export { TerceraLogo }
