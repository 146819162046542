import { IconButton } from '@mui/material'
import { IMaterialIconButton } from './IMaterialIconButton.interface'

/**
 * Renders a single icon button
 * @param props
 * @returns A single icon button
 */
const MaterialIconButton = (props: IMaterialIconButton): JSX.Element => {
  const { children, ...rest } = props
  return <IconButton {...rest}>{children}</IconButton>
}

export { MaterialIconButton }
