import { Alert, AlertProps } from '@mui/material'

/**
 * Renders a alert component in material style
 * @param props
 * @returns A alert component
 */
const MaterialAlert = (props: AlertProps): JSX.Element => {
  return <Alert {...props} />
}

export { MaterialAlert }
