import EmailIcon from '@mui/icons-material/Email'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single envelope icon
 * @param props
 * @returns A single envelope icon
 */
const MaterialEnvelopeIcon = (props: SvgIconProps): JSX.Element => {
  return <EmailIcon {...props} />
}

export { MaterialEnvelopeIcon }
