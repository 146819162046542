/**
 * Renders the current year
 * @param props
 * @returns Renders the current year
 */
const CurrentYear = (): JSX.Element => {
  return(
     <>{new Date().getFullYear()}</>
  )
}

export { CurrentYear }
