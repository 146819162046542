import { SxProps } from '@mui/material'

const navbarA11YWrapperStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  py: 1.5,
}

const themeTogglerStyles: SxProps = {
  padding: 0,
  marginRight: {
    md: 0.5,
  },
  display: {
    xs: 'none',
    md: 'flex',
  },
}

export const metaNavbarStyles = {
  themeTogglerStyles: themeTogglerStyles,
  A11YWrapperStyles: navbarA11YWrapperStyles,
}
