export const xxllncLogoStyles = {
  height: {
    xs: 10,
    md: 15,
  },
  width: '100%',
  display: 'block',
  p: 0,
  paddingLeft: 0.5,
  img: {
    width: '40px',
  },
  marginTop: { xs: -0.1, md: -0.2 },
}
