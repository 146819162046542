import { Tooltip, TooltipProps } from '@mui/material'

/**
 * Renders a tooltip in material-ui style
 * @param props
 * @returns - A tooltip in material style
 */
const MaterialTooltip = (props: TooltipProps) => {
  const { children, ...rest } = props
  return <Tooltip {...rest}>{children}</Tooltip>
}

export { MaterialTooltip }
