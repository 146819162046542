import { useState, useLayoutEffect } from 'react'

/**
 * Calculates the size of the window when the window is being resized
 * @returns
 */
export const useWindowResize = () => {
  const [size, setSize] = useState([0, 0])

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}
