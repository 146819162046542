import { SxProps } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { availibleThemes } from '../../../config/material-theme.config'
import { A11YContext } from '../../../contexts/A11YContext'
import { MaterialBrightnessIcon } from '../../atoms/material-brightness-icon/MaterialBrightnessIcon.component'
import { MaterialIconButton } from '../../atoms/material-icon-button/MaterialIconButton.component'
import { themeTogglerStyles as styles } from './themeToggler.styles'

/**
 * Renders a theme toggler molecule
 * @returns a theme toggler
 */
const ThemeToggler = (props: { sx?: SxProps; classnames?: string }): JSX.Element => {
  const { t } = useTranslation()
  const { currentTheme, setTheme } = useContext(A11YContext)
  const isDark = Boolean(currentTheme === availibleThemes.DARK)
  const _styles = styles({ theme: currentTheme })

  return (
    <MaterialIconButton
      aria-label={t('a11y.labels.CHANGE_THEME')}
      disableFocusRipple={true}
      disableTouchRipple={true}
      sx={_styles.button}
      className={`btn ${props.classnames}`}
      onClick={() => (isDark ? setTheme(availibleThemes.LIGHT) : setTheme(availibleThemes.DARK))}
      color='inherit'
      {...props}
    >
      <MaterialBrightnessIcon level={isDark ? 7 : 4} sx={_styles.icon} />
    </MaterialIconButton>
  )
}

export { ThemeToggler }
