import { SxProps } from '@mui/material'
import {
  availibleThemes,
  fontSizes,
  zaaksysteemBackgroundColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const contentContainerStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? zaaksysteemBackgroundColorHex : 'primary.main',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '25px',
  height: 'calc(100vh - 50px)'
})

const titleBoxStyles = (a11y: Partial<IA11Y>): SxProps => ({
  display: 'block',
  marginBottom: 2,
  'h2': {
    fontSize: fontSizes.bigger,
    textAlign: 'center'
  },
  color: a11y.theme === availibleThemes.LIGHT ? 'inherit' : 'rgba(255, 255, 255, 1)'
})

export const notFoundPageStyles = (a11y: IA11Y) => {
  return {
    contentContainer: contentContainerStyles(a11y),
    titleBoxStyles: titleBoxStyles(a11y),
  }
}
