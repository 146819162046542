import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { MaterialAlertTitle } from '../../atoms/material-alert-title/MaterialAlertTitle.component'
import { MaterialAlert } from '../../atoms/material-alert/MaterialAlert.component'
import { noPlansFoundWrapperStyles as styles } from './noPlansFoundWarning.styles'

/**
 * Renders a warning to be used when no plans are found
 * @returns - A warning component
 */
const NoPlansFoundWarning = (): JSX.Element => {
  const { t } = useTranslation()
  const _styles = styles()

  return (
    <Box sx={_styles.alertWrapper}>
      <MaterialAlert severity='warning'>
        <MaterialAlertTitle>{t('results.NO_RESULTS_FOUND_TITLE')}</MaterialAlertTitle>
        {t('results.NO_RESULTS_FOUND_TEXT')}
      </MaterialAlert>
    </Box>
  )
}

export { NoPlansFoundWarning }
