import { runInAction, makeAutoObservable } from 'mobx'

/**
 * DossierStore
 */
class DosierStore {
  isDossierLoading: boolean

  /**
   * Constructor
   */
  constructor() {
    makeAutoObservable(this)

    this.isDossierLoading = false
  }

  /**
   * Setter for the isDossierLoading value
   * @param value The value to set
   */
  setIsDossierLoading(value: boolean) {
    runInAction(() => {
      this.isDossierLoading = value
    })
  }
}

export default DosierStore
