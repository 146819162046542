import { AxiosResponse } from 'axios'
import { runInAction, makeAutoObservable } from 'mobx'
import { IPlan } from '../services/plans/IPlan.interface'

/**
 * PlanStore
 */
class PlanStore {
  isLoading: boolean
  planSearchResults: IPlan[]

  /**
   * Constructor
   */
  constructor() {
    makeAutoObservable(this)

    this.isLoading = false
    this.planSearchResults = []
  }

  /**
   * Setter for the isLoading value
   * @param value The value to set
   */
  setIsLoading(value: boolean) {
    runInAction(() => {
      this.isLoading = value
    })
  }

  /**
   * Setter for the plan search results
   * @param value The value to set
   */
  setPlanSearchResults(value: IPlan[]) {
    runInAction(() => {
      this.planSearchResults = value
    })
  }

  /**
   * Normalizes the fetched plans
   * @param fetchResult - The fetch result from the GET plans endpoint
   * @returns - A normalized resultset
   */
  normalizePlans(fetchResult: AxiosResponse<IPlan[]>) {
    const results: IPlan[] = []

    const normalizePlan = (index: number) => {
      return {
        datum: fetchResult.data[index].datum,
        naam: fetchResult.data[index].naam,
        eigenaar: fetchResult.data[index].eigenaar,
        cbs: fetchResult.data[index].cbs,
        status: fetchResult.data[index].status,
        type: fetchResult.data[index].type,
        idn: fetchResult.data[index].idn,
        deleted: fetchResult.data[index].deleted,
        imroVersion: fetchResult.data[index].imroVersion,
      }
    }

    for (let index = 0; index < fetchResult.data.length; index = index + 1) {
      const isPlanDeleted = fetchResult.data[index].deleted

      if (isPlanDeleted) {
        results.push(normalizePlan(index))
      }
      if (!isPlanDeleted) {
        results.push(normalizePlan(index))
      }
    }

    return results
  }
}

export default PlanStore
