import { MainHeader } from '../../organisms/main-header/MainHeader.component'
import { ObservingMetaHeader } from '../../organisms/meta-header/MetaHeader.component'
import { MainFooter } from '../../organisms/main-footer/MainFooter.component'
import { IBaseTemplate } from './IBaseTemplate.interface'
import { baseTemplateStyles as styles } from './BaseTemplate.styles'
import { Box } from '@mui/system'
import { CookieNotification } from '../../molecules/cookie-notification/CookieNotification.component'
import { BackToTopButton } from '../../molecules/back-to-top-button/BackToTopButton.component'
import { FloatingContactButton } from '../../molecules/floating-contact-button/FloatingContactButton.component'
import { useEffect } from 'react'

/**
 * Renders the base template
 * @returns a base template component
 */
const BaseTemplate = (props: IBaseTemplate): JSX.Element => {
  const { children } = props

  useEffect(() => {
    if (document.body.classList.contains('p-0')) {
      document.body.classList.remove('p-0');
    }
  }, [])

  return (
    <Box sx={styles} className={'template template--base'}>
      <main className='content'>
        <ObservingMetaHeader />
        <MainHeader />
        {children}
        <MainFooter />
      </main>
      <BackToTopButton />
      <FloatingContactButton />
      <CookieNotification host={window.location.host} />
    </Box>
  )
}

export { BaseTemplate }
