import { AlertTitle, AlertTitleProps } from '@mui/material'

/**
 * Renders a alert title component in material style
 * @param props
 * @returns A alert title component
 */
const MaterialAlertTitle = (props: AlertTitleProps): JSX.Element => {
  return <AlertTitle {...props} />
}

export { MaterialAlertTitle }
