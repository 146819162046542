import Button from '@mui/material/Button'
import { IMaterialButton } from './IMaterialButton.interface'

/**
 * Renders single a material UI button
 * @param props
 * @returns A single material-ui button
 */
const MaterialButton = (props: IMaterialButton): JSX.Element => {
  return <Button {...props} />
}

export { MaterialButton }
