import axios, { AxiosResponse } from 'axios'
import { API } from './api'
import { IMunicipality } from './IMunicipality.interface'

const municipalitiesAxios = axios.create()

/**
 * Fetches all municipalities
 * @returns A list of all municipalities
 */
export const fetchMunicipalities = (): Promise<AxiosResponse<IMunicipality[]>> => {
  return municipalitiesAxios.get(API.fetchMunicipalities)
}
