import { observer } from 'mobx-react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Homepage } from '../components/pages/homepage/Homepage.component'
import { TermsAndConditionsPage } from '../components/pages/terms-and-conditions-page/TermsAndConditionsPage.component'
import { availableRoutes } from './availableRoutes'
import { NotFoundPage } from '../components/pages/not-found-page/NotFoundPage.component'

/**
 * Renders the default router
 * @returns
 */
const BaseRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index={true} element={<Homepage />} />
        <Route path={availableRoutes.TERMS_AND_CONDITIONS} element={<TermsAndConditionsPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

const ObservingBaseRouter = observer((): JSX.Element | null => {
  return <BaseRouter />
})

export { BaseRouter, ObservingBaseRouter }
