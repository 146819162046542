import { apiConfig } from '../../config/api.config'
import { environmentConfig } from '../../config/environment.config'

const IS_DEV = process.env.NODE_ENV === environmentConfig.development

export const API = {
  fetchPlansForMunicipality: IS_DEV
    ? `${apiConfig.prefix}/plans/{{cbs}}`
    : `${apiConfig.prefix}/plans/{{cbs}}`,
  fetchPlansByDate: (date: string) =>
    IS_DEV ? `${apiConfig.prefix}/plans?date=${date}` : `${apiConfig.prefix}/plans?date=${date}`,
  fetchPlanByIdnNumber: (idn: string) =>
    IS_DEV ? `${apiConfig.prefix}/plans?idn=${idn}` : `${apiConfig.prefix}/plans?idn=${idn}`,
}
