import { ThemeProvider } from '@mui/material'
import React from 'react'
import {
  availibleThemes,
  darkTheme,
  lightTheme,
  themeCookie,
} from '../config/material-theme.config'

/**
 * Sets the default color values that are available in the color context
 */
export const A11YContext = React.createContext({
  currentTheme: availibleThemes.LIGHT,
  setTheme: (name: string): void => {}, // eslint-disable-line
})

/**
 * Provides the possibillity to get the current theme and to set a new theme
 * @param props - any component that uses theme based styles
 * @returns - The color context provider
 */
const CustomThemeProvider = (props: { children: JSX.Element | JSX.Element[] }) => {
  const { children } = props

  const currentTheme = localStorage.getItem(themeCookie) || availibleThemes.LIGHT

  const [themeName, _setThemeName] = React.useState(currentTheme)

  const theme = themeName === availibleThemes.LIGHT ? lightTheme : darkTheme

  const setThemeName = (name: string) => {
    localStorage.setItem(themeCookie, name)
    _setThemeName(name)
  }

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  }

  return (
    <A11YContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </A11YContext.Provider>
  )
}

export default CustomThemeProvider
