import { ArrowCircleUp } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single up icon in a circle
 * @param props
 * @returns A single circle up icon
 */
const MaterialCircleUpIcon = (props: SvgIconProps): JSX.Element => {
  return <ArrowCircleUp {...props} />
}

export { MaterialCircleUpIcon }
