import { Box } from '@mui/material'
import { IMetaNavbar, IMetaNavbarOptionalProps } from './IMetaNavbar.interface'
import { ThemeToggler } from '../theme-toggler/ThemeToggler.component'
import { metaNavbarStyles as _styles } from './metaNavbar.styles'
import { observer } from 'mobx-react'

/**
 * Renders the meta navbar component
 * @param props
 * @returns A meta navbar
 */
const MetaNavbar = (props: IMetaNavbar): JSX.Element => {
  return (
    <Box
      component={'div'}
      fontSize={'default'}
      style={{
        display: props.box?.style?.display,
        justifyContent: props.box?.style?.justifyContent,
      }}
      className={'navbar navbar--meta'}
    >
      <Box sx={_styles.A11YWrapperStyles} className={'navbar__a11y'}>
        <ThemeToggler sx={_styles.themeTogglerStyles} />
      </Box>
    </Box>
  )
}

const defaultProps: IMetaNavbarOptionalProps = {
  box: {
    style: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}

MetaNavbar.defaultProps = defaultProps

/* eslint-disable @typescript-eslint/no-explicit-any */
const ObservingMetaNavbar = observer((props: any): JSX.Element | null => {
  return <MetaNavbar {...props} />
})
/* eslint-enable @typescript-eslint/no-explicit-any */

export { MetaNavbar, ObservingMetaNavbar }
