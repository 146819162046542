import { Box } from '@mui/material'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { A11YContext } from '../../../contexts/A11YContext'
import { planResultStyles as styles } from './planResult.styles'

/**
 * Renders a table header for the plan results
 * @returns The table header component for the plan results
 */
const PlanResultHead = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)
  const _styles = styles({ theme: currentTheme })

  return (
    <Box sx={{ ..._styles.columnWrapper, ..._styles.tableHeader }}>
      <Box sx={{ ..._styles.beforeIconColumn, paddingLeft: 2.5 }}> </Box>
      <Box sx={{ ..._styles.dateColumn, ..._styles.tableHeaderItem }}>
        {t('results.table.headers.DATE_COLUMN_TEXT')}
      </Box>
      <Box sx={{ ..._styles.nameColumn, ..._styles.tableHeaderItem }}>
        {' '}
        {t('results.table.headers.NAME_COLUMN_TEXT')}
      </Box>
      <Box sx={{ ..._styles.ownerColumn, ..._styles.tableHeaderItem }}>
        {t('results.table.headers.OWNER_COLUMN_TEXT')}
      </Box>
      <Box sx={{ ..._styles.statusColumn, ..._styles.tableHeaderItem }}>
        {t('results.table.headers.STATUS_COLUMN_TEXT')}
      </Box>
      <Box sx={{ ..._styles.typeColumn, ..._styles.tableHeaderItem }}>
        {t('results.table.headers.TYPE_COLUMN_TEXT')}
      </Box>
      <Box sx={{ ..._styles.afterIconColumn, paddingLeft: 0 }}> </Box>
      <Box sx={{ ..._styles.afterIconColumn, paddingLeft: 1 }}> </Box>
      <Box sx={{ ..._styles.afterIconColumn, paddingLeft: 1 }}> </Box>
    </Box>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const ObservingPlanResultHead = observer((props: any): JSX.Element | null => {
  return <PlanResultHead {...props} />
})
/* eslint-enable @typescript-eslint/no-explicit-any */

export { PlanResultHead, ObservingPlanResultHead }
