import Box from '@mui/material/Box'
import { MaterialLink } from '../../atoms/material-link/MaterialLink.component'
import { MaterialHomeIcon } from '../../atoms/material-home-icon/MaterialHomeIcon.component'
import { MaterialEnvelopeIcon } from '../../atoms/material-envelope-icon/MaterialEnvelopeIcon.component'
import { MaterialPhoneIcon } from '../../atoms/material-phone-icon/MaterialPhoneIcon.component'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import { contactInfoStyles as styles } from './contactInfo.styles'
import { useContext } from 'react'
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders a contactInfo component to display in the footer
 * @param props
 * @returns Renders a contactInfo component
 */
const ContactInfo = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)

  const _styles = styles({ theme: currentTheme })

  return (
    <Box component='div' className='contact--info' sx={_styles.wrapper}>
      <Stack sx={_styles.stack}>
        <Box sx={_styles.box}>
          <MaterialHomeIcon fontSize='small' sx={_styles.icon} />
          <MaterialLink
            sx={_styles.link}
            href={'https://www.google.nl/maps/place/Burgemeester+Van+Tuinenplein+6,+Kampen'}
            target='_blank'
          >
            {t('footer.contactInfo.ADDRESS')}, {t('footer.contactInfo.ZIP')}{' '}
            {t('footer.contactInfo.CITY')}
          </MaterialLink>
        </Box>
        <Box sx={_styles.box}>
          <MaterialEnvelopeIcon fontSize='small' sx={_styles.icon} />
          <MaterialLink sx={_styles.link} href={`mailto:${t('footer.contactInfo.EMAIL_ADDRESS')}`}>
            {t('footer.contactInfo.EMAIL_ADDRESS')}
          </MaterialLink>
        </Box>
        <Box sx={_styles.box}>
          <MaterialPhoneIcon fontSize='small' sx={_styles.icon} />
          <MaterialLink sx={_styles.link} href={`tel:${t('footer.contactInfo.PHONE')}`}>
            {t('footer.contactInfo.PHONE')}
          </MaterialLink>
        </Box>
      </Stack>
    </Box>
  )
}

export { ContactInfo }
