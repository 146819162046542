import { SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'

const headingWrapperStyles: SxProps = {
  paddingBottom: 1,
  marginBottom: 2,
  borderBottom: '1px solid',
  borderColor: grey[200],
}

const wrapperMobileStyles: SxProps = {
  pt: 3,
  pb: 3,
  px: 1,
  display: { md: 'none' },
}

const wrapperDesktopStyles: SxProps = {
  display: { xs: 'none', md: 'flex' },
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: {
    xs: 3,
    md: 5,
  },
  pb: {
    xs: 2,
    md: 5,
  },
}

export const planResultsHeaderStyles = () => {
  return {
    wrapperMobile: wrapperMobileStyles,
    wrapperDesktop: wrapperDesktopStyles,
    headingWrapper: headingWrapperStyles,
  }
}
