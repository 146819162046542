import { SxProps } from '@mui/material'
import {
  availibleThemes,
  zaaksysteemBackgroundColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const contentContainerStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? zaaksysteemBackgroundColorHex : 'primary.main',
  display: 'block',
  py: 5,
  minHeight: {
    md: 'calc(100vh - 565px)',
  },
})

export const termsAndConditionsPageStyles = (a11y: IA11Y) => {
  return {
    contentContainer: contentContainerStyles(a11y),
  }
}
