import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { IProjectLogo, IProjectLogoOptionalProps } from './IProjectLogo.interface'
import { projectLogoStyles as styles } from './projectLogo.styles'
import { useContext } from 'react'
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders the project logo component
 * @returns A single logo
 */
const ProjectLogo = (props: IProjectLogo): JSX.Element => {
  const { t } = useTranslation()
  const { primaryText, secondaryText } = props
  const { currentTheme } = useContext(A11YContext)

  const _styles = styles({ theme: currentTheme })

  return (
    <Box className={'logo logo--project'} sx={_styles.wrapper}>
      <MaterialTypography
        variant={'h4'}
        component={'h1'}
        sx={{
          fontSize: {
            xs: primaryText.fontSize.xs,
            md: primaryText.fontSize.md,
          },
          ..._styles.projectNameWrapper,
        }}
      >
        <Box component={'span'} sx={_styles.projectNameMobile}>
          {t('PROJECT_NAME')}
        </Box>
        <Box component={'span'} sx={_styles.projectNameDesktop}>
          {t('PROJECT_NAME')}
        </Box>
      </MaterialTypography>
      <MaterialTypography
        component={'span'}
        sx={{
          fontSize: {
            xs: secondaryText.fontSize.xs,
            md: secondaryText.fontSize.md,
          },
          ..._styles.projectSlogan,
        }}
      >
        {t('PROJECT_SLOGAN')}
      </MaterialTypography>
    </Box>
  )
}

const defaultProps: IProjectLogoOptionalProps = {
  primaryText: {
    fontSize: {
      xs: 'h6.fontSize',
      md: 'subtitle1.fontSize',
    },
  },
  secondaryText: {
    fontSize: {
      md: 'subtitle2.fontSize',
    },
  },
}

ProjectLogo.defaultProps = defaultProps

export { ProjectLogo }
