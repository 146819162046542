import Link, { LinkProps } from '@mui/material/Link'

/**
 * Renders a single link
 * @param props
 * @returns Renders a single link
 */
const MaterialLink = (props: LinkProps): JSX.Element => {
  const { children, ...rest } = props
  return <Link {...rest}>{children}</Link>
}

export { MaterialLink }
