import { emptyTemplateStyles as styles } from './EmptyTemplate.styles'
import { Box } from '@mui/system'
import { CookieNotification } from '../../molecules/cookie-notification/CookieNotification.component'
import { IEmptyTemplate } from './IEmptyTemplate.interface'
import { useEffect } from 'react'

/**
 * Renders a empty template
 * @returns a empty template component
 */
const EmptyTemplate = (props: IEmptyTemplate): JSX.Element => {
  const { children } = props

  useEffect(() => {
    document.body.classList.add('p-0');
  }, [])

  return (
    <Box sx={styles} className={'template template--empty'}>
      <main className='content'>
        {children}
      </main>
      <CookieNotification host={window.location.host} />
    </Box>
  )
}

export { EmptyTemplate }
