import Box from '@mui/material/Box'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { useTranslation } from 'react-i18next'
import { CurrentYear } from '../../atoms/current-year/CurrentYear.component'
import { copyrightStyles as styles } from './copyright.styles'
import { useContext } from 'react'
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders a copyright component to display in the footer
 * @param props
 * @returns Renders a copyright component
 */
const Copyright = (): JSX.Element => {
  const { t } = useTranslation()
  const { currentTheme } = useContext(A11YContext)

  const _styles = styles({ theme: currentTheme })

  return (
    <Box component='div' className='copyright' sx={_styles}>
      <MaterialTypography sx={_styles} noWrap>
        &copy; {t('footer.copyright.COMPANY_NAME')} <CurrentYear />
      </MaterialTypography>
    </Box>
  )
}

export { Copyright }
