import { SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import { availibleThemes, fontSizes } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const wrapperStyles = (a11y: Partial<IA11Y>): SxProps => ({
  width: {
    xs: '100%',
    md: '350px',
  },
  fontSize: fontSizes.default,
  '&:before': {
    borderBottom: '1px solid',
    borderColor: grey[200],
  },
  input: {
    paddingBottom: 1,
    color: 'secondary.contrastText',
  },
  svg: {
    color: a11y.theme === availibleThemes.LIGHT ? 'primary.main' : 'secondary.contrastText',
  },
})

export const planResultsFilterStyles = (a11y: IA11Y) => {
  return {
    wrapper: wrapperStyles(a11y),
  }
}
