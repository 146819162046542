import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { A11YContext } from '../../../contexts/A11YContext'
import { FilterIcon } from '../../atoms/filter-icon/FilterIcon.component'
import { MaterialInputAdorment } from '../../atoms/material-input-adorment/MaterialInputAdorment.component'
import { MaterialInput } from '../../atoms/material-input/MaterialInput.component'
import { planResultsFilterStyles as styles } from './planResultsFilter.styles'
import useDebounce from '../../../hooks/useDebounce.hook'
import planStore from '../../../contexts/PlanStoreContext'
import { IPlan } from '../../../services/plans/IPlan.interface'
import { observer } from 'mobx-react'
import { IPlanResultsFilter } from './IPlanResultsFilter.interface'
import { IListOption } from '../list-option/IListOption.interface'

/**
 * Renders an input field that can be used to filter results
 * @returns A input field that filters plan results
 */
const PlanResultsFilter = observer((props: IPlanResultsFilter): JSX.Element | null => {
  // State hooks
  const [inputValue, setInputValue] = useState<string>('')
  const [fetchedPlanResults, setFetchedPlanResults] = useState<IPlan[]>([])

  // Contexts
  const { currentTheme } = useContext(A11YContext)

  // Third party hooks
  const { t } = useTranslation()
  const debouncedInputValue = useDebounce<string>(inputValue, 250)

  // Props
  const { municipality } = props

  // Functions
  const filterPlans = () => {
    return fetchedPlanResults.filter((result) => {
      const checkFor = inputValue.toLowerCase()

      return (
        result.naam.toLowerCase().includes(checkFor) ||
        result.datum.toString().toLowerCase().includes(checkFor) ||
        result.cbs.toLowerCase().includes(checkFor) ||
        result.idn.toLowerCase().includes(checkFor) ||
        result.status.toLowerCase().includes(checkFor) ||
        result.type.toLowerCase().includes(checkFor) ||
        result.cbs.toLowerCase().includes(checkFor)
      )
    })
  }

  // Effect hooks
  useEffect(() => {
    if (!inputValue) {
      setFetchedPlanResults(planStore.planSearchResults)
    }
  }, [planStore.planSearchResults])

  useEffect(() => {
    if (!inputValue) {
      planStore.setPlanSearchResults(fetchedPlanResults)
    }

    const filteredPlanResults = filterPlans()

    planStore.setIsLoading(true)
    planStore.setPlanSearchResults(filteredPlanResults)
    planStore.setIsLoading(false)
  }, [debouncedInputValue])

  // Constants
  const _styles = styles({ theme: currentTheme })

  // Render
  return (
    <MaterialInput
      className='plan-filter'
      role={'searchbox'}
      aria-label={t('a11y.labels.FILTER_PLANS')}
      sx={{
        ..._styles.wrapper,
        // ...(planStore.planSearchResults.length === 0 && { display: 'none' }),
      }}
      value={inputValue}
      onChange={(event) => setInputValue(event.target.value)}
      placeholder={
        municipality
          ? t('results.FILTER_RESULTS_PLACEHOLDER', {
              municipality: (municipality as IListOption).label,
            })
          : t('results.FILTER_RESULTS_PLACEHOLDER_NO_MUNICIPALITY')
      }
      startAdornment={
        <MaterialInputAdorment position='start'>
          <FilterIcon fontSize='small' sx={{ marginTop: '-4px' }} />
        </MaterialInputAdorment>
      }
    />
  )
})

export { PlanResultsFilter }
