import { Box, Container } from '@mui/system'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { A11YContext } from '../../../contexts/A11YContext'
import { ObservingMetaNavbar } from '../../molecules/meta-navbar/MetaNavbar.component'
import { ThemeToggler } from '../../molecules/theme-toggler/ThemeToggler.component'
import { metaHeaderStyles as styles } from './metaHeader.styles'

/**
 * Renders the meta header component
 * @returns the meta header
 */
const MetaHeader = (): JSX.Element => {
  const { currentTheme } = useContext(A11YContext)

  const _styles = styles({ theme: currentTheme })

  /**
   * Render
   */
  return (
    <>
      <Box className={'header header--meta'} component={'div'} sx={_styles}>
        <Box className={'header__a11y'}>
          <Container>
            <Box className={'a11y__wrapper'}>
              <Box component={'div'}>
                <ThemeToggler classnames={'a11y__theme-toggler'} />
              </Box>
            </Box>
          </Container>
        </Box>
        <Container className={'header__navbar'}>
          <ObservingMetaNavbar />
        </Container>
      </Box>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const ObservingMetaHeader = observer((props: any): JSX.Element | null => {
  return <MetaHeader {...props} />
})
/* eslint-enable @typescript-eslint/no-explicit-any */

export { MetaHeader, ObservingMetaHeader }
