import ArticleIcon from '@mui/icons-material/Article'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single article icon
 * @param props
 * @returns A single article icon
 */
const MaterialArticleIcon = (props: SvgIconProps): JSX.Element => {
  return <ArticleIcon {...props} />
}

export { MaterialArticleIcon }
