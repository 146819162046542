import { SxProps } from '@mui/material'
import { fontSizes } from '../../../config/material-theme.config'

const alertWrapperStyles: SxProps = {
  marginBottom: 0,
  '.MuiTypography-root': {
    fontSize: fontSizes.default,
  },
  '.MuiAlert-message': {
    fontSize: fontSizes.default,
  },
  px: {
    xs: 1,
    md: 0,
  },
}

export const noPlansFoundWrapperStyles = () => {
  return {
    alertWrapper: alertWrapperStyles,
  }
}
