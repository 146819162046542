/**
 * Renders a image
 * @param props
 * @returns Renders a image
 */
const Image = (props: Partial<HTMLImageElement>): JSX.Element => {
  return <img src={props.src} alt={props.alt} width={props.width} height={props.height} />
}

export { Image }
