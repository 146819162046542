import { NavLink } from 'react-router-dom'
import { MaterialTypography } from '../material-typography/MaterialTypography.component'
import { IMaterialNavLink } from './IMaterialNavLink.interface'

/**
 * Renders a single navigation link
 * @param props
 * @returns Renders a single navigation link
 */
const MaterialNavLink = (props: IMaterialNavLink): JSX.Element => {
  const { children, sx, ...rest } = props
  return (
    <NavLink {...rest}>
      <MaterialTypography sx={sx} component={'span'}>
        {children}
      </MaterialTypography>
    </NavLink>
  )
}

export { MaterialNavLink }
