import { Box } from '@mui/material'
import { Image } from '../../atoms/image/Image.component'
import { MaterialLink } from '../../atoms/material-link/MaterialLink.component'
import { xxllncLogoStyles as _styles } from './xxllncLogo.styles'

/**
 * Renders a xxllnc logo component to display in the footer
 * @param props
 * @returns Renders a xxllnx logo component
 */
const XxllncLogo = (props: { type: 'light' | 'dark' }): JSX.Element => {
  return (
    <Box>
      <MaterialLink sx={_styles} href={'https://www.xxllnc.nl/'} target='_blank'>
        <Image
          className='logo logo--xxllnc'
          alt='logo-xxllnc'
          width={40}
          height={10}
          src={
            props.type === 'dark'
              ? '/assets/images/logo-xxllnc-light.svg'
              : '/assets/images/logo-xxllnc-dark.svg'
          }
        />
      </MaterialLink>
    </Box>
  )
}

export { XxllncLogo }
