import { SxProps } from '@mui/material'
import { fontSizes } from '../../../config/material-theme.config'

const titleStyles: SxProps = {
  fontSize: {
    xs: fontSizes.default.md,
    md: fontSizes.default.md + 1,
  },
  color: 'secondary.contrastText',
  marginBottom: 2,
}

const textStyles: SxProps = {
  fontSize: fontSizes.default,
  color: 'secondary.contrastText',
  '&:not(:last-of-type)': {
    marginBottom: 3,
  },
}

export const termsAndConditionsStyles = {
  title: titleStyles,
  text: textStyles,
}
