import { grey } from '@mui/material/colors'
import {
  availibleThemes,
  backgroundDefaultDarkHex,
  zaaksysteemBackgroundColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

export const municipalitiesSearchStyles = (i11y: IA11Y) => ({
  listbox: {
    backgroundColor: zaaksysteemBackgroundColorHex,
    borderRadius: 3,
  },
  paper: {
    marginTop: { xs: 3, md: 1 },
  },
  input: {
    width: {
      md: 350,
    },
    border: i11y.theme === availibleThemes.LIGHT ? '1px solid' : '1px solid',
    borderColor: {
      xs: i11y.theme === availibleThemes.LIGHT ? 'primary.main' : 'secondary.contrastText',
      md: i11y.theme === availibleThemes.LIGHT ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)',
    },
    input: {
      color: {
        xs: 'secondary.contrastText',
        md:
          i11y.theme === availibleThemes.LIGHT
            ? zaaksysteemBackgroundColorHex
            : 'secondary.contrastText',
      },
    },
    label: {
      color: {
        xs: i11y.theme === availibleThemes.LIGHT ? grey[700] : 'secondary.contrastText',
        md:
          i11y.theme === availibleThemes.LIGHT ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
      },
    },
    borderRadius: 1,
    '.MuiFormLabel-root': {
      md: {
        color: i11y.theme === availibleThemes.LIGHT ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255, 255, 255, 0.6)',
      }
    },
    div: {
      paddingRight: '20px !important',
      span: {
        color: i11y.theme === availibleThemes.LIGHT ? '' : 'secondary.contrastText',
      },
    },
  },
  root: {
    fieldset: { border: 'none' },
    '&.Mui-focused': {
      label: {
        backgroundColor: {
          xs: i11y.theme === availibleThemes.LIGHT ? 'white' : backgroundDefaultDarkHex,
          md: 'primary.main',
        },
        color: {
          xs: i11y.theme === availibleThemes.LIGHT ? grey[700] : zaaksysteemBackgroundColorHex,
          md: i11y.theme === availibleThemes.LIGHT ? 'white' : 'secondary.contrastText',
        },
      },
    },
  },
})
