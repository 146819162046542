import { IPlanResults } from './IPlanResults.interface'
import { VariableSizeList as List } from 'react-window'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useWindowResize } from '../../../hooks/useWindowResize.hook'
import { Box } from '@mui/material'
import { FullHeightContainerElement } from '../../../library-extensions/react-window/FullHeightContainerElement.extension'
import { A11YContext } from '../../../contexts/A11YContext'
import { IPlan } from '../../../services/plans/IPlan.interface'
import handleViewport from 'react-in-viewport'
import { toJS } from 'mobx'
import { MaterialCircularProgress } from '../../atoms/material-circular-progress/MaterialCircularProgress.component'
import { planResultStyles as styles } from './planResults.styles'
import { NoPlansFoundWarning } from '../../molecules/no-plans-found-warning/NoPlansFoundWarning.component'
import { IPlanResultCard } from '../../molecules/plan-result-card/IPlanResultCard.interface'

/* eslint-disable @typescript-eslint/no-explicit-any */
const LoadMoreChecker = (props: { forwardedRef: any }) => <div ref={props.forwardedRef}> </div>
const LoadMoreCheckerInViewport = handleViewport(LoadMoreChecker)
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Renders wrapper that renders multiple plan result cards
 * @returns A single logo
 */
const withPlanResults = (
  ResultComponent: React.FC<IPlanResultCard>,
  BeforeComponent?: React.FC,
): ((props: IPlanResults) => JSX.Element) => {
  const WithPlanResults = (props: IPlanResults): JSX.Element => {
    // States
    const [resultsToDisplay, setResultsToDisplay] = useState<IPlan[]>([])
    const [showLoadMoreIndicator, setShowLoadMoreIndicator] = useState<boolean>(false)
    const [handledResults, setHandledResults] = useState<boolean>(false)

    // Contexts
    const { currentTheme } = useContext(A11YContext)

    // References
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const listRef = useRef<any>()
    const sizeMap = useRef<any>({})
    /* eslint-enable @typescript-eslint/no-explicit-any */

    // Custom hooks
    const [windowWidth] = useWindowResize()

    // Constants
    const itemsToConcat = props.itemsToConcat || 10
    const estimatedCardHeightInPx = props.estimatedItemHeight || 250
    const _styles = styles({ theme: currentTheme })

    useEffect(() => {
      concatResultsToDisplay(itemsToConcat)
      setHandledResults(true)
    }, [props.results])

    useEffect(() => {
      if (!showLoadMoreIndicator) {
        return
      }

      const handle = async () => {
        if (resultsToDisplay.length >= itemsToConcat) {
          await timeout(2000)
          concatResultsToDisplay(itemsToConcat)
        }
      }

      handle()
    }, [showLoadMoreIndicator])

    /**
     * Pauses the execution for a given time
     * @param delay The amount of time to wait in milliseconds
     * @returns
     */
    const timeout = (delay: number) => {
      return new Promise((res) => setTimeout(res, delay))
    }

    /**
     * Concats the visible results array with the available results array
     * @param concatCount - The amount of items to concat to the visible array
     * @returns void
     */
    const concatResultsToDisplay = (concatCount: number) => {
      const startResults = []
      const currentResults = toJS(props.results)

      if (currentResults.length === 0) {
        return
      }

      const getIterations = (a: number, b: number): number => (a > b ? b : a)
      const iterations = getIterations(resultsToDisplay.length + concatCount, currentResults.length)

      for (let index = 0; index < iterations; index++) {
        startResults.push(currentResults[index])
      }

      setResultsToDisplay(startResults)
    }

    /**
     * Sets the size for the card items based on their dimensions
     */
    const setSize = useCallback((index: number, size: number): number => {
      sizeMap.current = { ...sizeMap.current, [index]: size }
      return listRef.current.resetAfterIndex(index)
    }, [])

    /**
     * Gets the size of a card item for a card at a given index
     * @param index - The card index to get the size from
     * @returns - The size of the card at the given index
     */
    const getSize = (index: number) => sizeMap.current[index] || estimatedCardHeightInPx

    /**
     * Render loader
     */
    if (props.isLoading || !handledResults) {
      return (
        <Box className={'loader'} sx={_styles.loaderWrapper}>
          <MaterialCircularProgress size={'small'} sx={_styles.loader} />
        </Box>
      )
    }

    /**
     * Render no results message
     */
    if (resultsToDisplay.length === 0 && !props.isLoading) {
      return <NoPlansFoundWarning />
    }

    /**
     * Render happy flow
     */
    return (
      <>
        {BeforeComponent && <BeforeComponent />}
        <List
          ref={listRef}
          estimatedItemSize={estimatedCardHeightInPx}
          height={window.innerHeight}
          width={'100%'}
          itemCount={resultsToDisplay.length}
          outerElementType={FullHeightContainerElement}
          itemSize={getSize}
          itemData={resultsToDisplay}
        >
          {({ data, index, style }) => (
            <>
              <Box sx={index % 2 ? _styles.oddRows : _styles.evenRows} style={style}>
                <ResultComponent
                  data={data}
                  index={index}
                  setSize={setSize}
                  windowWidth={windowWidth}
                />
              </Box>
            </>
          )}
        </List>
        {showLoadMoreIndicator && resultsToDisplay.length !== props.results.length && (
          <Box sx={_styles.loaderWrapper}>
            <MaterialCircularProgress size={'small'} sx={_styles.loader} />
          </Box>
        )}
        <LoadMoreCheckerInViewport
          onEnterViewport={() => setShowLoadMoreIndicator(true)}
          onLeaveViewport={() => setShowLoadMoreIndicator(false)}
        />
      </>
    )
  }

  return WithPlanResults
}

export { withPlanResults }
