import { Link as RouterLink } from 'react-router-dom';
import { MaterialButton } from '../../atoms/material-button/MaterialButton.component'
import { MaterialHomeIcon } from '../../atoms/material-home-icon/MaterialHomeIcon.component'
import { useTranslation } from 'react-i18next';
import { backToHomePageButtonStyles as styles } from './backToHomePageButton.styles';
import { useContext } from 'react';
import { A11YContext } from '../../../contexts/A11YContext'

/**
 * Renders a a button that when clicked, takes the user back to the homepage
 */
const BackToHomepageButton = (): JSX.Element | null => {
    const { t } = useTranslation()
    const { currentTheme } = useContext(A11YContext)
    const _styles = styles({ theme: currentTheme })

    return (
        <MaterialButton 
            sx={_styles} 
            component={RouterLink} 
            to={'/'} 
            variant='outlined'
        >
            <><MaterialHomeIcon /> <span>{t('suggestionButtons.BACK_HOME_LABEL')}</span></>
        </MaterialButton>
    )
}

export { BackToHomepageButton }