import { useTranslation } from 'react-i18next'
import { MaterialTypography } from '../../atoms/material-typography/MaterialTypography.component'
import { termsAndConditionsStyles as _styles } from './termsAndConditions.style'

/**
 * Renders the content in the terms and conditions page
 * @returns
 */
const TermsAndConditions = () => {
  const { t } = useTranslation()

  return (
    <>
      <MaterialTypography sx={_styles.title} component={'h2'}>
        {t('pages.termsAndConditions.FIRST_TITLE')}
      </MaterialTypography>
      <MaterialTypography sx={_styles.text}>
        {t('pages.termsAndConditions.FIRST_TEXT')}
      </MaterialTypography>
      <MaterialTypography sx={_styles.title} component={'h2'}>
        {t('pages.termsAndConditions.SECOND_TITLE')}
      </MaterialTypography>
      <MaterialTypography sx={_styles.text}>
        {t('pages.termsAndConditions.SECOND_TEXT')}
      </MaterialTypography>
    </>
  )
}

export { TermsAndConditions }
