import TextField, { TextFieldProps } from '@mui/material/TextField'

/**
 * Renders a single textfield
 * @param props
 * @returns Renders a single textfield
 */
const MaterialTextfield = (props: TextFieldProps): JSX.Element => {
  return <TextField {...props} />
}

export { MaterialTextfield }
