import { SxProps } from '@mui/material'
import { availibleThemes, fontSizes } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const wrapperStyles: SxProps = {
  width: 'fit-content',
}

const projectNameWrapperStyles: SxProps = {
  fontWeight: '500',
  color: 'white',
  marginBottom: 0.3,
  fontSize: {
    xs: 22,
    md: 30,
  },
}

const projectNameMobileStyles: SxProps = {
  display: { xs: 'block', md: 'none', marginLeft: 8 },
}

const projectNameDesktopStyles: SxProps = {
  display: { xs: 'none', md: 'block' },
}

const projectSloganStyles = (a11y: Partial<IA11Y>): SxProps => ({
  color: a11y.theme === availibleThemes.LIGHT ? 'white' : 'secondary.contrastText',
  fontSize: {
    md: fontSizes.default.md - 2,
  },
  display: { xs: 'none', md: 'block' },
})

export const projectLogoStyles = (a11y: IA11Y) => {
  return {
    wrapper: wrapperStyles,
    projectNameWrapper: projectNameWrapperStyles,
    projectNameMobile: projectNameMobileStyles,
    projectNameDesktop: projectNameDesktopStyles,
    projectSlogan: projectSloganStyles(a11y),
  }
}
