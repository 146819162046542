import { Input, InputProps } from '@mui/material'

/**
 * Renders a input adorment component for a given position
 * @param props
 * @returns A input adorment component
 */
const MaterialInput = (props: InputProps): JSX.Element => {
  return <Input {...props} />
}

export { MaterialInput }
