import useScrollPosition from '../../../hooks/useScrollPosition.hook'
import { MaterialCircleUpIcon } from '../../atoms/material-arrow-circle-up-icon/MaterialCircleUpIcon.component'
import { MaterialFab } from '../../atoms/material-fab/MaterialFab.component'
import { backToTopButtonStyles as styles } from './backToTopButton.styles'

/**
 * Renders a single back to top button with interactivity
 * @returns
 */
const BackToTopButton = (): JSX.Element | null => {
  const scrollPosition = useScrollPosition()
  const showButtonAfter = 650
  const showBackToTopButton = scrollPosition <= showButtonAfter

  return (
    <MaterialFab
      href='#'
      className='btn'
      sx={{
        ...styles,
        right: !showBackToTopButton ? 20 : '-40px',
      }}
    >
      <MaterialCircleUpIcon className='icon' />
    </MaterialFab>
  )
}

export { BackToTopButton }
