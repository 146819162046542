import HomeIcon from '@mui/icons-material/Home'
import { SvgIconProps } from '@mui/material/SvgIcon'

/**
 * Renders a single home icon
 * @param props
 * @returns A single home icon
 */
const MaterialHomeIcon = (props: SvgIconProps): JSX.Element => {
  return <HomeIcon {...props} />
}

export { MaterialHomeIcon }
