import { ClickAwayListener, ClickAwayListenerProps } from '@mui/material'

/**
 * Renders a single click away listener component
 * @returns A click away listener component
 */
const MaterialClickAwayListener = (props: ClickAwayListenerProps): JSX.Element => {
  const { children, ...rest } = props
  return <ClickAwayListener {...rest}>{children}</ClickAwayListener>
}

export { MaterialClickAwayListener }
