import { availibleThemes } from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'
import { SxProps } from '@mui/material'

export const suggestionButtonGroupStyles = (a11y: Partial<IA11Y>): SxProps => ({
    display: { xs: 'block', sm: 'flex' },
    justifyContent: 'center',
    alignItems: 'center',
    'span.divider': {
      marginLeft: 1,
      marginRight: 1,
      display: { xs: 'block' },
      textAlign: { xs: 'center' },
      marginTop: { xs: 1 },
      marginBottom: { xs: 1 },
    },
    color: a11y.theme === availibleThemes.LIGHT ? 'inherit' : 'rgba(255, 255, 255, 1)'
})
