import { SxProps } from '@mui/material'
import {
  availibleThemes,
  fontSizes,
  xxllncFooterColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const columnWrapperStyles: SxProps = (a11y: Partial<IA11Y>) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSizes.default.xs,
  color: 'secondary.contrastText',
  borderBottom: a11y.theme === availibleThemes.LIGHT ? null : '1px solid',
  borderColor: 'secondary.contrastText',
})

const dateColumnStyles: SxProps = {
  padding: 1.7,
  width: 100,
}

const nameColumnStyles: SxProps = {
  padding: 1.7,
  width: 350,
}

const ownerColumnStyles: SxProps = {
  padding: 1.7,
  width: 200,
}

const statusColumnStyles: SxProps = {
  padding: 1.7,
  width: 150,
}

const typeColumnStyles: SxProps = {
  padding: 1.7,
  width: 300,
}

const beforeIconColumnStyles: SxProps = {
  padding: 1.7,
  width: 25,
  '.icon': {
    fontSize: fontSizes.default,
    marginTop: 0,
    color: 'secondary.contrastText',
    cursor: 'pointer',
  },
}

const afterIconColumnStyles: SxProps = {
  padding: 1.7,
  width: 25,
  '.icon': {
    fontSize: fontSizes.default,
    marginTop: 0,
    color: 'secondary.contrastText',
    cursor: 'pointer',
  },
}

const tableHeaderItemStyles: SxProps = {
  fontWeight: 'bold',
}

const tableHeaderStyles: SxProps = {
  borderBottom: '1px solid',
  borderColor: xxllncFooterColorHex,
}

const chipStyles: SxProps = (a11y: Partial<IA11Y>) => ({
  fontSize: 10,
  minWidth: 42,
  textAlign: 'center',
  backgroundColor: a11y.theme === availibleThemes.DARK ? 'secondary.contrastText' : '',
})

export const planResultStyles = (a11y: IA11Y) => {
  return {
    columnWrapper: columnWrapperStyles(a11y),
    dateColumn: dateColumnStyles,
    nameColumn: nameColumnStyles,
    ownerColumn: ownerColumnStyles,
    statusColumn: statusColumnStyles,
    typeColumn: typeColumnStyles,
    beforeIconColumn: beforeIconColumnStyles,
    afterIconColumn: afterIconColumnStyles,
    tableHeaderItem: tableHeaderItemStyles,
    tableHeader: tableHeaderStyles,
    chip: chipStyles(a11y),
  }
}
