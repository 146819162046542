import { MaterialEnvelopeIcon } from '../../atoms/material-envelope-icon/MaterialEnvelopeIcon.component'
import { MaterialFab } from '../../atoms/material-fab/MaterialFab.component'
import { floatingContactButtonStyles as styles } from './floatingContactButton.styles'

/**
 * Renders a floating contact button on the bottom right
 */
const FloatingContactButton = () => {
  const _styles = styles()

  return (
    <MaterialFab
      sx={_styles.fab}
      color='primary'
      aria-label='contact'
      href='mailto:support@tercera.nl'
    >
      <MaterialEnvelopeIcon />
    </MaterialFab>
  )
}

export { FloatingContactButton }
