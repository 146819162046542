import React from 'react'
import ReactDOM from 'react-dom/client'
import './config/i18next.config'
import './index.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import A11YContextProvider from './contexts/A11YContext'
import { ObservingBaseRouter } from './routers/BaseRouter.component'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <A11YContextProvider>
      <ToastContainer />
      <ObservingBaseRouter />
    </A11YContextProvider>
  </React.StrictMode>,
)
