import { SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import {
  availibleThemes,
  fontSizes,
  xxllncFooterColorHex,
  xxllncPrimaryBackgroundColorHex,
} from '../../../config/material-theme.config'
import { IA11Y } from '../../../interfaces/A11Y'

const logoWrapperStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? xxllncFooterColorHex : 'background.default',
  color: a11y.theme === availibleThemes.LIGHT ? 'initial' : 'secondary.contrastText',
  px: 0,
  pt: 2,
  display: 'flex',
  mb: { xs: 2, md: 0 },
})

const copyrightWrapperStyles: SxProps = {
  textAlign: 'left',
  display: 'flex',
  px: 1,
  pt: 2,
  pb: { xs: 1, md: 2 },
}

const contactInfoWrapperStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? xxllncFooterColorHex : 'background.default',
  '.MuiTypography-root': {
    lineHeight: 1.2,
    marginTop: -0.2,
    color: 'secondary.contrastText',
  },
  borderColor:
    a11y.theme === availibleThemes.LIGHT
      ? xxllncPrimaryBackgroundColorHex
      : 'secondary.contrastText',
  px: 0,
  pb: 2,
})

const textStyles = (a11y: Partial<IA11Y>): SxProps => ({
  fontSize: fontSizes.default.xs,
  marginTop: 0,
  marginLeft: { xs: 1, md: 1.5 },
  color: a11y.theme === availibleThemes.DARK ? 'white' : 'secondary.contrastText',
})

const topFooterStyles = (a11y: Partial<IA11Y>): SxProps => ({
  backgroundColor:
    a11y.theme === availibleThemes.LIGHT ? xxllncFooterColorHex : 'background.default',
  borderBottom: a11y.theme === availibleThemes.LIGHT ? null : '1px solid',
  borderColor: 'secondary.contrastText',
  paddingBottom: { xs: 4, md: 1 },
})

const seperatorStyles = (a11y: Partial<IA11Y>): SxProps => ({
  marginLeft: { xs: 1, md: 1.5 },
  marginTop: -0.2,
  color: a11y.theme === availibleThemes.DARK ? 'white' : grey[500],
  display: 'inline-block',
})

const subFooterWrapperStyles: SxProps = {
  display: { xs: 'block', md: 'flex' },
  justifyContent: 'space-between',
  alignItems: { xs: 'flex-end', md: 'center' },
}

const linkStyles = (a11y: Partial<IA11Y>): SxProps => ({
  fontSize: fontSizes.default.xs,
  color: a11y.theme === availibleThemes.DARK ? 'white' : grey[500],
  marginRight: 2,
  marginBottom: { xs: 0.5, md: 0 },
  display: 'block',
  textDecoration: 'none',
})

const subfooterFixedContainerStyles = (a11y: Partial<IA11Y>): SxProps => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: a11y.theme === availibleThemes.DARK ? grey[500] : 'white',
  borderTop: '1px solid',
  borderColor: 'primary.main',
})

const navigationWrapperStyles: SxProps = {
  display: 'flex',
  'a:last-of-type': {
    marginRight: 0,
  },
  px: { xs: 1, md: 0 },
  marginBottom: { xs: 2, md: 0 },
}

export const mainFooterStyles = (a11y: IA11Y) => ({
  logoWrapper: logoWrapperStyles(a11y),
  copyrightWrapper: copyrightWrapperStyles,
  contactInfoWrapper: contactInfoWrapperStyles(a11y),
  text: textStyles(a11y),
  topFooter: topFooterStyles(a11y),
  seperator: seperatorStyles(a11y),
  subFooterWrapper: subFooterWrapperStyles,
  link: linkStyles(a11y),
  subfooterFixedContainer: subfooterFixedContainerStyles(a11y),
  navigationWrapper: navigationWrapperStyles,
})
