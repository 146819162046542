import { CardContent, CardContentProps } from '@mui/material'

/**
 * Renders a wrapper for the contents of a mui card
 * @param props
 * @returns A wrapper for the content of a mui card
 */
const MaterialCardContentWrapper = (props: CardContentProps): JSX.Element => {
  const { children, ...rest } = props
  return <CardContent {...rest}>{children}</CardContent>
}

export { MaterialCardContentWrapper }
