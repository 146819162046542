import { Card, CardProps } from '@mui/material'

/**
 * Renders a card in material style
 * @param props
 * @returns A card in material style
 */
const MaterialCard = (props: CardProps): JSX.Element => {
  const { children, ...rest } = props
  return <Card {...rest}>{children}</Card>
}

export { MaterialCard }
