import { useEffect, useState } from 'react'

/**
 * Hook for saving values after a specific time
 * @param value The value to save
 * @param delay The delay before the item is saved
 * @returns A genneric type
 */
function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  const timeout = 500

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || timeout)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
